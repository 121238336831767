import React from "react";
import {Controller,} from "./Components";
import Auth from "./Auth";

class Signed extends Controller {
    async componentDidMount() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        const result = await (new Auth().signin('linkedin', params['code']));
        console.log(result)
    }

    render() {
        return <section className="pt-4 pt-md-11">
            <div className="container center">
            </div>
        </section>
    }
}

export {Signed}
