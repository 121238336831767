import React from "react";
import {
    Button,
    Card,
    Controller,
    LoadController,
    MyComponent,
    SpinnerBox, TimeLeft,
} from "./Components";
import {PaypalPayment, TestPayment, StripePayment} from "./Payments";

class RequestView extends LoadController {
    constructor(props) {
        super(props);
        this.request = this.props.match.params.request;
        this.token = this.props.match.params.token;
        if (this.token) {
            localStorage.setItem('token', this.token);
        }
        this.confirm = this.confirm.bind(this);
        this.resendLink = this.resendLink.bind(this);
        this.showMore = this.showMore.bind(this);
        this.state = {confirm: false};
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.match !== nextProps.match || this.state !== nextState;
    }

    dataToLoad() {
        return {data: this.api.getRequest(this.request, this.token), paymentProviders: this.api.loadPayProviders(1)};
    }

    async resendLink() {
        this.setState({loading: true});
        await this.api.refreshRequest(this.request);
        this.setState({resent: true, loading: false})
    }

    handleAuthError(error) {
        if (this.state.resent) {
            return <div className="container col-lg-5 col-sm-10">
                <h1>Your link has expired</h1>
                <p>We have sent your new link to your email.</p>
            </div>;
        }

        return <div className="container col-lg-5 col-sm-10">
            <h1>Your link has expired</h1>
            <Button onClick={this.resendLink}>Resend the link</Button>
            { this.state.loading && <SpinnerBox/> }
        </div>;
    }

    preloader = () => (
        {
            "ph-col-2": 'ph-avatar', 'ph-col-10': '',
            'ph-col-12': [
                {
                    'ph-row': ['ph-col-4 big', "ph-col-8 empty big"]
                },
                {
                    'ph-row': ["ph-col-2 big", "ph-col-12", "ph-col-12", "ph-col-12"]
                }
            ]
        }
    );

    confirm() {
        this.setState({confirm: true});
    }

    cancel() {

    }

    async componentDidMount(){
        await super.componentDidMount();
        if (this.textElement) {
            this.setState({clipped: this.textElement.scrollHeight > this.textElement.clientHeight});
        }
    }

    showMore() {
        this.setState({showMore: true});
    }

    renderLoaded() {
        const request = this.state.data.request;
        document.title = `Confirm your request to ${request.userTo.shadow_email}`;

        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    <UserView user={request.userTo}/>

                    <Card>
                        <div className={ this.state.showMore ? "" : "clipped"} ref={ el => this.textElement = el}>
                            <b>{request.subject}</b>
                            <p className="display-linebreak">
                                {request.txt}
                            </p>
                        </div>
                        { this.state.clipped && !this.state.showMore && <button className="btn btn-sm btn-secondary-soft m-2" onClick={this.showMore}>More</button> }
                    </Card>

                    <br/>
                    <RequestState request={this.state.data.request} invoice={this.state.data.invoice} paymentProviders={this.state.paymentProviders} cancel={this.cancel} />
                </div>
            </div>
        </div>;
    }
}

const RequestState = props => {
    const request = props.request;
    console.log(request);
    switch (request.state) {
        case 'init':
            return <PaymentView request={request} invoice={props.invoice} paymentProviders={props.paymentProviders}
                                cancel={props.cancel}/>;
        case 'sent':
            return <div className="alert alert-primary-desat">Your request is awaiting response, <TimeLeft time={new Date(request.expire_at)}/></div>;
        case 'replied':
            return <></>
        case 'timeout':
            return <div className="alert alert-secondary">User did not respond in time, your deposit has been refunded.</div>;
        default:
            return <div className="alert alert-secondary">{request.state}</div>;
    }
};

class PaymentView extends MyComponent {
    render() {
        const request = this.props.request;
        const invoice = this.props.invoice;
        return <>
            <p>
                {request.userTo.name} requires <b>${Math.round(invoice.amount/100)}</b> application fee to review requests.
                Charged only if responded within 24 hours.

                {/*<a href="" data-toggle="collapse" data-target="#fee_details">FAQ</a>*/}
            </p>

            <ConfirmForm request={request} invoice={invoice} paymentProviders={this.props.paymentProviders} />
        </>
    }
}

class UserView extends MyComponent {
    render() {
        const user = this.props.user;
        return <div className="row" id="user_view">
            <div className="col-md-8 p-0">
                <div className="m-2">
                    <img src={user.avatar} alt={user.name} className="avatar_small img-thumbnail rounded-circle pull-left"/>
                    <div>
                        <b>{user.name}</b> <br/> <a href={"mailto:" + user.shadow_email} target="_blank">{user.shadow_email}</a>
                    </div>
                    <Verification list={user.verification || []} />
                </div>
            </div>
            {/*<div className="col-md-4 pull-right  p-0">*/}
            {/*    <button className="btn btn-secondary-soft btn-sm m-2" data-toggle="collapse" data-target="#user_details">Details</button>*/}
            {/*</div>*/}


            {/*<div className="collapse card card-border border-primary shadow-light-lg mb-6 mb-md-8 aos-init aos-animate"*/}
            {/*     id="user_details"*/}
            {/*     data-aos="fade-up"*/}
            {/*     data-parent="#user_view"*/}
            {/*>*/}
            {/*    <div className="card-body">*/}
            {/*        <div>*/}
            {/*            <div className="list-group list-group-flush">*/}
            {/*                <a className="list-group-item text-reset text-decoration-none" href="#!">*/}
            {/*                    <p className="mb-1 display-linebreak">*/}
            {/*                        {this.props.user.about}*/}
            {/*                    </p>*/}

            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}

        </div>
    }
}

class Verification extends Controller {
    render() {
        return <ul>
            { this.props.list.map(element => <VerificationElement verification={element}/> ) }
        </ul>
    }
}

class VerificationElement extends Controller {
    render() {
        return <>
            { this.props.verification.type }
        </>
    }
}

class ConfirmForm extends Controller {
    async pay(pay_type, data = {}) {
        this.setState({loading: true});
        await this.api.executePayment(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        window.location.reload(false);
    }

    async checkout(pay_type, data = {}) {
        this.setState({loading: true});
        data['success'] = window.location.protocol + '//' + window.location.host + `/request/${this.props.request.uuid}`
        data['cancel'] = window.location.protocol + '//' + window.location.host + `/request/${this.props.request.uuid}`

        const redirect = await this.api.checkout(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        this.redirect(redirect);
    }

    render() {
        return <>
            <div className="text-center">
                { this.props.paymentProviders.map( provider => {
                    switch (provider.type) {
                        case 'paypal':
                            return <PaypalPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'test':
                            return <TestPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'stripe':
                            return <StripePayment invoice={this.props.invoice} context={this} params={provider}/>;
                    }
                }) }
                {this.state.loading && <SpinnerBox/>}
            </div>
        </>;
    }
}

export {RequestView};
