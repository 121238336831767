import React from "react";
import {ArrowButton, Card, Controller, UserContext,} from "./Components";

class ConnectionProfileView extends Controller {
    static contextType = UserContext

    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
        this.addGroup = this.addGroup.bind(this)
        this.onChange = this.onChange.bind(this)
        this.deleteGroup = this.deleteGroup.bind(this)

        const user = this.context
        this.state = {
            connections: user?.connections || {}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.context.connections !== prevState.connections) {
            this.setState({ connections: this.context.connections });
        }
    }

    async onSubmit(event) {
        if (event) {
            event.preventDefault()
        }
        const user = this.context
        this.repo.update(user.id)

        console.log(this.state.connections)
        await this.api.updateConnectionProfile(this.state.connections)
        this.button.stopLoading()
    }

    async addGroup(event) {
        let connections = this.state.connections
        connections[this.state.group_name] = {}
        this.setState({connections: connections, groupSelected: this.state.group_name, group_name: ''})
    }

    onChange(param_name, state) {
        let connections = this.state.connections
        connections[this.state.groupSelected][param_name] = state
        this.setState({connections: connections})
    }

    deleteGroup(event) {
        let connections = this.state.connections
        delete connections[this.state.groupSelected]
        this.setState({connections: connections, groupSelected: null})
    }

    render() {
        const user = this.context
        if (!user) {
            return <></>
        }

        const connectionParams = user.connections[this.state.groupSelected] || {}

        const connections = [
            {
                name: 'immediate_call',
                caption: 'Unannounced immediate calls',
                description: 'You will be called as a usual call.',
            },
            {
                name: 'announced_call',
                caption: 'Call request with caller name and a reason to call.',
                description: 'You will get notification with caller name amd reason.\n' +
                    '                                You can accept or decline the call within 5 minutes.',
            },
            {
                name: 'scheduled_call',
                caption: 'Scheduled calls',
                description: 'You will be called withing your allowed time slots.',
            },
            {
                name: 'urgent_message',
                caption: 'Urgent message.',
                description: 'Urgent priority message with sound and notification, require immediate attention.',
            },
            {
                name: 'priority_message',
                caption: 'Priority message',
                description: 'High priority message with sound and notification, 5 minutes reply.',
            },
            {
                name: 'regular_message',
                caption: 'Regular message',
                description: 'A regular message with 1 hour reply window.',
            },
        ]

        return <div className="section">
            <div className="container">
                <br/>
                <h2>
                    Connection params
                </h2>

                <div className="input-group mb-3">
                    <label className="input-group-text" htmlFor="inputGroupSelect01">Group</label>
                    <select className="form-select" id="inputGroupSelect01" onChange={ event => this.setState({groupSelected: event.target.value}) }>
                        <option>Choose...</option>
                        {Object.keys(user.connections).map(group => <option value={group} selected={this.state.groupSelected === group}>{group}</option>)}
                    </select>
                    <ArrowButton onclick={this.deleteGroup}>Delete</ArrowButton>
                </div>

                <div className="input-group mb-3">
                <input onChange={this.updateState('group_name')} defaultValue={this.state.group_name} className="form-control"/>
                <ArrowButton onclick={this.addGroup}>Add group</ArrowButton>
                </div>

                <form onSubmit={this.onSubmit}>
                    {
                        connections.map(connection =>
                            <ConnectionParam
                                connection={connection}
                                connectionParams = {connectionParams[connection.name]}
                                user={user}
                                group={this.state.groupSelected}
                                key={connection.name}
                                onChange={state => this.onChange(connection.name, state)}
                            />
                        )
                    }

                    <ArrowButton onclick={this.onSubmit} ref={ref => this.button = ref}>Save</ArrowButton>
                </form>
            </div>
        </div>
    }
}

class ConnectionParam extends Controller {


    constructor(props) {
        super(props)

        this.state = this.props.connectionParams

        this.onChecked = this.onChecked.bind(this)
        this.onFeeChecked = this.onFeeChecked.bind(this)
        this.onCostChange = this.onCostChange.bind(this)
        this.sync = this.sync.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.group !== prevProps.group) {
            this.setState({
                allow: this.props.connectionParams?.allow,
                require_fee: this.props.connectionParams?.require_fee,
                fee: this.props.connectionParams?.fee,
            })
        }
    }

    sync() {
        this.props.onChange(this.state)
    }

    onCostChange(event) {
        this.setState({fee: event.target.value * 100}, this.sync)
    }

    onChecked(event) {
        this.setState({allow: event.target.checked}, this.sync)
    }

    onFeeChecked(event) {
        this.setState({require_fee: event.target.checked}, this.sync)
    }

    render() {
        const connection = this.props.connection

        if (!this.state) {
            return <></>
        }

        return <div className="form-check form-switch">
            <Card>
                <input className="form-check-input" type="checkbox" role="switch" checked={this.state.allow} id={connection.name} onChange={this.onChecked}/>
                <label className="form-check-label" htmlFor={connection.name}>{connection.caption}</label>
                <p className="small">
                    {connection.description}
                </p>


                {this.state.allow &&
                    <div>
                        <input className="form-check-input" type="checkbox" role="switch" checked={this.state.require_fee} id={"fee:" + connection.name} onChange={this.onFeeChecked}/>
                        <label className="form-check-label" htmlFor={"fee:" + connection.name} >Require fee</label>

                        {this.state.require_fee && <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input className="form-control" type="number" step="0.01"
                                     defaultValue={this.state.fee ? this.state.fee / 100 : ''}
                                     onChange={this.onCostChange}/>
                            <span className="input-group-text">per request</span>

                        </div>}
                    </div>
                }
            </Card>
        </div>
    }
}

export {ConnectionProfileView}
