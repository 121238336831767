import {Container} from "../Components";

class LocalRepo {
    constructor(apiService) {
        this.api = apiService
    }

    get token() {
        return localStorage.getItem('token')
    }

    async setToken(token) {
        localStorage.removeItem('myselfId')
        localStorage.setItem('token', token)
        return this.updateMyself()
    }

    async loadMyself() {
        const myselfId = localStorage.getItem('myselfId')
        if (myselfId) {
            return await this.getUser(myselfId)
        }

        if (this.token) {
            return this.updateMyself()
        }
    }

    async updateMyself() {
        localStorage.removeItem('myselfId')
        if (!this.token) {
            console.warn("No token!")
            return null
        }

        try {
            const user = await this.api.getUser()
            localStorage.setItem('myselfId', user.id)
            this.updateModel(user)
            return user
        } catch (e) {
            console.error(e)
            return null
        }
    }

    async getUser(id) {
        return this.get('user', id)
    }

    async get(name, id) {
        if (id === undefined || id === 'undefined') {
            return null
        }

        let model = this.getModel(id)
        if (model) {
            return model
        }

        model = await this.api.getModel(name, id)
        if (model) {
            this.updateModel(model)
        }
        return model
    }

    update(id) {
        localStorage.removeItem("models:" + id)
    }

    updateModel(model) {
        localStorage.setItem("models:" + model.id, JSON.stringify(model))
    }

    getModel(id) {
        return null

        const data = localStorage.getItem("models:" + id)
        if (data) {
            return JSON.parse(data)
        }
    }


}

export default LocalRepo
