import {Container} from "./Components";

class Auth {
    api = Container.getApi();

    logout(auth2 = null) {
        auth2 && auth2.signOut().then(function () {
            console.log('User signed out.');
        });

        localStorage.removeItem('token');
    }

    async signin(service, token) {
        const result = await this.api.signin(service, {token: token});
        localStorage.setItem('token', result.token);
        localStorage.setItem('myselfId', result.user.id)
        return result.user;
    }
}

export default Auth;