import React from "react";
import {LoadController, MyComponent, UserContext} from "./Components";

class InMessage extends MyComponent {
    render() {
        return <li className="list-group-item in-message">
            In: {this.props.message.text}<br/>
            <small>{this.props.message.status}</small>
        </li>
    }
}

class OutMessage extends LoadController {
    dataToLoad() {
        return {author: this.repo.getUser(this.props.message.author_id)}
    }

    renderLoaded() {
        const author = this.state.author
        return <li className="list-group-item out-message">
            {/*<a href={`/user/${author.path}`}><img src={author.avatar || 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} alt={author.name} className="avatar_xsmall img-thumbnail rounded-circle pull-left"/></a>*/}
            {this.props.message.text}<br/>
            {/*<small>{this.props.message.status}</small>*/}
        </li>
    }
}

class Message extends MyComponent {
    static contextType = UserContext
    render() {
        return <OutMessage message={this.props.message}/>
    }
}

export {Message}