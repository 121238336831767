import React, {useState} from "react";
import {ArrowButton, Controller, UserContext,} from "./Components";

class SearchView extends Controller {
    constructor(props) {
        super(props)
        this.state = {results: []}
        this.onSubmit = this.onSubmit.bind(this)
    }

    static contextType = UserContext

    async onSubmit(event) {
        if (event) {
            event.preventDefault()
        }

        const results = await this.api.search(this.state)
        this.button.stopLoading()
        console.log(results)
        this.setState({results})
    }

    render() {
        const user = this.context
        // if (!user) {
        //     return <></>
        // }

        return <div className="section">
            <div className="container">
                <h1>Search</h1>

                <hr/>

                <div className="form-label-group">
                    <input placeholder="Search" className="form-control form-control-flush" id="search" onChange={this.updateState('search')}/>
                    <label htmlFor="search">Search</label>
                </div>

                <ArrowButton onclick={this.onSubmit} ref={ref => this.button = ref}>Search</ArrowButton>

                <SearchResult results={this.state.results}/>
            </div>
        </div>
    }
}

class UserResults extends Controller {
    render() {
        const user = this.props.children
        const path = user.path || user.id
        return <tr>
            <td>
                <a href={"/user/" + path}>{user.name}</a>
            </td>
            <td>
                {user.location}
            </td>
            <td>
                ${user.compensation/100}
            </td>
        </tr>
    }
}

class SearchResult extends Controller {
    render() {
        return <table className="table">
            {this.props.results.map(user => <UserResults>{user}</UserResults>)}
        </table>
    }
}

export {SearchView}
