import React from "react";
import {CollapsibleItemList, Container, H2, Invite, LoadController, MyComponent, TimeLeft,} from './Components'
const api = Container.getApi();

const STATES = {
    init: 'Payment awaiting',
    sent: 'Pending',
    timeout: 'User didn\'t answer',
    accepted: 'User accepted',
    declined: 'User declined',
    recruiter_declined: 'Declined',
    recruiter_accepted: 'Sent interview invitation',
    interview_secured: 'Interview deposed',
};

const DECLINE_REASONS = {
    product: 'Not interesting in product',
    skills: 'Skills mismatch',
    compensation: 'Small compensation',
    not_open: 'Not open for the new opportunities',
    other: 'Other'
};

const Message = props => <div className="message-block">
    <div className={(props.message.to_id === props.user.id ? 'my-message' : 'they-message') + " display-linebreak" }>
        {props.message.txt}
        <div className="sent_date">
            { props.message.created_at ? <span>Sent {new Date(props.message.created_at).toLocaleString()}</span> : <span>Sending...</span> }
        </div>
    </div>
</div>;

const MessagesList = props => <div className="messages-list"> { props.messages.map(message => <Message message={message} me={props.me} user={props.user}/>) } </div>;

class Messenger extends MyComponent {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            messages: this.props.messages,
        };
        this.onChange = this.onChange.bind(this);
        this.send = this.send.bind(this);
    }

    onChange(event) {
        this.setState({message: event.target.value})
    }

    async send(e) {
        const text = this.state.message;
        this.pushMessage({txt: text, to_id: this.props.user.id});
        this.setState({message: ''});
        this.pushMessage(await this.props.send(text), true);
    }

    pushMessage(msg, replace = false) {
        let messages = this.state.messages;
        if (replace) {
            messages.pop();
        }
        messages.push(msg);
        this.setState({messages: messages});
    }

    render() {
        return <div>
            <MessagesList messages={this.state.messages} me={this.props.me} user={this.props.user}/>
            <form className="input-group input-group-sm mb-3" onSubmit={event => {this.send().catch(console.error); event.preventDefault();}}>
                <input type="text" className="form-control" placeholder="Send a message"
                       aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={this.onChange} value={this.state.message}/>
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" onClick={this.send}>Send</button>
                </div>
            </form>
        </div>
    }
}

class InviteItemBody extends MyComponent {
    constructor(props) {
        super(props);
        this.state = {show: true, text: `Hello, \n\nBest Regards,\n`};
        this.onChange = this.onChange.bind(this);
        this.send = this.send.bind(this);
        this.done = this.done.bind(this);
    }

    onChange(event) {
        this.setState({text: event.target.value});
    }

    send() {
        api.sendReply(this.props.item.uuid, this.state.text).then(this.done).catch(e => this.setState({error: e.message}));
    }

    done() {
        this.setState({done: true});
    }

    render() {
        const request = this.props.item;
        return <>
            <div className="padding5  display-linebreak">
                {request.txt}
            </div>
            { !this.state.done &&  <><textarea onChange={this.onChange} className="form-control" value={this.state.text} rows="20"/>
                <button className="btn btn-success btn-sm" onClick={this.send}>Send</button>
                </> }
            {this.state.error && <div className="alert alert-warning m-2">{this.state.error}</div>}
        </>;
    }
}

const InviteSubHeader = props => {
    switch (props.item.state) {
        case Invite.INIT:
            return <>{STATES[props.item.state]}</>;
        case Invite.SENT:
            return <><TimeLeft time={new Date(props.item.expire_at)}/> {STATES[props.item.state]}</>;
        default:
            return <>{STATES[props.item.state]}</>;
    }
};

const ToArchive = props => props.invite.rec_archivable && <button className="btn btn-light btn-sm" onClick={event => {
    props.click(props.invite);
}}><span className="fe fe-archive"/> Archive</button>;

const InviteHeader = props => <>
    <UserFromInfo user={props.item.userFrom}/> &nbsp; &lt;{props.item.email_from}&gt; &nbsp; {props.item.subject}
</>;

const UserFromInfo = props => (props.user ? <span><img src={props.user.avatar} alt="" className="avatar_ico"/> <b>{props.user.name}</b></span> : <>No user info</>);

class IncomingView extends LoadController {
    constructor(props) {
        super(props);
        this.state = {loaded: false, activeInvites: [], allInvites: []};
        this.decline = this.decline.bind(this);
        this.archive = this.archive.bind(this);
        this.token = this.props.match.params.token;
        if (this.token) {
            localStorage.setItem('token', this.token);
        }
    }

    dataToLoad() {
        return {
            user: this.api.getUser(),
            activeInvites: this.api.incomingRequest(),
            allInvites: this.api.incomingRequest(),
        };
    }

    renderLoaded() {
        return <section className="pt-4 pt-md-11">
            <div className="container">
                <H2>Incoming</H2>

                {(this.state.allInvites &&
                    <CollapsibleItemList Header={InviteHeader} SubHeader={InviteSubHeader}
                                         ItemBody={InviteItemBody} items={this.state.allInvites} context={this}
                                         id="all_invites"/>)}
                {(!this.state.allInvites.length && this.loaded() &&
                    <div>You don't have active contacts at the moment. </div>)}
            </div>
        </section>
    }
}

export {IncomingView};
