class ApiService {
    constructor(url) {
        this.url = url;
    }

    async getUser() {
        return this.get('myself');
    }

    async fullUserProfile() {
        return this.get('fullUserProfile');
    }

    async joinWaitList() {
        return this.post('wait_list');
    }

    async loadUser(path) {
        return this.get('user-path/' + path);
    }

    async loadUserPublic(path) {
        return this.get('user-path-public/' + path);
    }

    async loadInvite(id) {
        return this.get('invite/' + id);
    }

    async loadInviteRec(id) {
        return this.get('invite/' + id, {who: 'rec'});
    }

    async loadInvoice(id) {
        return this.get('invoice/' + id);
    }

    async loadPayProviders(id) {
        return this.get(`invoice/${id}/pay_providers/`);
    }

    async updateUser(data) {
        return this.put('user', data);
    }

    async signin(service, payload) {
        return this.post('signin', {service: service, payload: payload});
    }

    async sendInitMessage(path, payload) {
        payload['user_path'] = path;
        return this.post('message/init', payload);
    }

    async sendMessage(dialog_id, text) {
        return this.post('message', {dialog_id, text});
    }

    async getMessages(dialog_id) {
        return this.get(`message/list/${dialog_id}`);
    }

    async getDialogs(path) {
        return this.get(`dialog/list/${path}`);
    }

    async connectionsRequests(path) {
        return this.get(`connections/list/${path}`);
    }

    async connectionDashboard(path) {
        return this.get(`connections/dashboard/${path}`);
    }

    async getDialog(path, created) {
        return this.get(`dialog/get/${path}/${created}`);
    }

    async getDialogToken(id, token) {
        return this.get(`dialog/getToken/${id}/${token}`);
    }

    async sendReply(request_uid, text) {
        return this.post(`request/reply`, {request_uid, text})
    }

    async dashboard() {
        return this.get('dashboard');
    }

    async loadData(name) {
        return this.get(`load-data/${name}`);
    }

    async sentInvites(params) {
        return this.get('invite/list/sent', params);
    }

    async incomingRequest() {
        return this.get('requests/incoming');
    }

    async activeUsers() {
        return this.get('users/active');
    }

    async sentRequest() {
        return this.get('requests/sent');
    }

    async accept(invite, answers) {
        return this.inviteAction(invite, 'user_accept', {answers: answers});
    }

    async user_read(invite) {
        return this.inviteAction(invite, 'user_read');
    }

    async decline(invite, reason) {
        return this.inviteAction(invite, 'user_decline', {reason: reason});
    }

    async recruiter_archive(invite) {
        return this.inviteAction(invite, 'recruiter_archive');
    }

    async archive_request(invite) {
        return this.inviteAction(invite, 'archive');
    }

    async refreshRequest(request_uuid) {
        return this.requestAction(request_uuid, 'refresh');
    }

    async recruiter_cancel(invite) {
        return this.inviteAction(invite, 'recruiter_cancel');
    }

    async recruiter_decline(invite, reason) {
        return this.inviteAction(invite, 'recruiter_decline', {reason: reason});
    }

    async inviteInterview(invite, message) {
        return this.inviteAction(invite, 'invite_interview', {message: message});
    }

    async executePayment(invoice_id, pay_type, payload = {}) {
        return this.post(`execute_payment`, {invoice_id: invoice_id, pay_type: pay_type, payload: payload});
    }

    async checkout(invoice_id, pay_type, payload = {}) {
        return this.post(`checkout`, {invoice_id: invoice_id, pay_type: pay_type, payload: payload});
    }

    async orderCallImmediate(user_id) {
        return this.post("call_immediate", {user_id: user_id});
    }

    async orderCallScheduled(user_id) {
        return this.post("call_scheduled", {user_id: user_id});
    }

    async orderConnection(user_id, connection_type) {
        return this.post("order_connection", {user_id: user_id, connection_type: connection_type});
    }

    async inviteAction(invite, action, params = {}) {
        params['action'] = action;
        return this.post(`invite/${invite.id}/action`, params);
    }

    async requestAction(request_uuid, action, params = {}) {
        params['action'] = action;
        return this.post(`request/action/${request_uuid}`, params);
    }

    async getRequest(request, token) {
        return this.get(`request/${request}/${token}`);
    }

    buildParams(params) {
        return Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    }

    async getModel(name, id) {
        return this.get(`model/load/${name}/${id}`)
    }

    async archiveDialog(id) {
        return this.updateModel('dialog', id, {status: 'archived'})
    }

    async updateProfile(id, data) {
        return this.updateModel('user', id, data)
    }

    async search(data) {
        return this.post("search", {search: data})
    }

    async transactions(data) {
        return this.get("transactions")
    }

    async sendInvitations(emails, text, comp) {
        return this.post('invitations/send', {emails, text, comp})
    }

    async updateConnectionProfile(connections) {
        return this.post('connection_profile', {connections})
    }

    //////

    async updateModel(name, id, data) {
        return this.put(`model/${name}/${id}`, data)
    }

    async get(path, params = {}) {
        return this.request(path + '?' + this.buildParams(params), 'GET')
    }

    async post(path, body= {}) {
        return this.request(path, 'POST', body)
    }

    async put(path, body={}) {
        return this.request(path, 'PUT', body)
    }

    async request(path, method, body={}) {
        let params = {
            method: method,
            headers: this.getHeaders(),
            mode: 'cors',
        };
        if (['POST', 'PUT'].includes(method)) {
            params['body'] = JSON.stringify(body);
        }
        try {
            const response = await fetch(this.url + path, params);
            return this.process_response(response);
        } catch (e) {
            if (e instanceof TypeError) {
                throw new ApiError(503, {error: "Can't connect to the server."})
            }
            throw e;
        }
    }

    hasToken() {
        return localStorage.getItem('token')
    }

    getHeaders() {
        return {
            'Access-Control-Allow-Origin':'*',
            'Content-Type': 'text/plain',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        };
    }

    async process_response(response) {
        console.log(response)

        if (response.status < 300) {
            return response.json();
        }

        let payload

        try {
            payload = await response.json()
        } catch (error) {
            payload = { error: response.statusText }
        }

        throw new ApiError(response.status, payload);
    }
}

class ApiError extends Error {
    constructor(status, payload) {
        super(payload['error']);
        this.status = status;
    }
}

export {
    ApiService,
    ApiError,
}
