import {ArrowButton, H2, MyComponent} from "./Components";
import React from "react";

class SendMessage extends MyComponent {
    constructor(props) {
        super(props)
        this.state = {message: ""}
    }

    render() {
        return <div className="col-12">
            <div className="text-center">
                <br/>
                <H2>Send a message</H2>
            </div>
            <span>
                        <div className="collapse" id="pay_faq" data-parent="#pay_faq_container">
                            <h4>What I'm paying for?</h4>
                            <p className="text-muted">
                                This payment compensates the time spent by the candidate for considering your job opening.
                                It is charged only if the candidate responds within 24 hours.
                            </p>

                            <h4>Why do I need to pay for that?</h4>
                            <p className="text-muted">
                                The candidate requires this application fee for every job they are reviewing.
                            </p>

                            <h4>Will I get money back if the candidate will decline the vacancy, fail interview or reject the offer?</h4>
                            <p className="text-muted">
                                No. The payment will be refunded only if the candidate didn't respond within 24 hours.
                            </p>
                        </div>

                    </span>

            <label htmlFor="message">Message</label>
            <textarea className="form-control" id="message" rows="10"
                      onChange={event => this.setState({message: event.target.value})}
                      value={this.state.message}/>
            <hr/>


            <div className="row justify-content-center">
                <div className="col-auto">
                    <ArrowButton onclick={event => this.props.sendInvitation(this.state.message)}
                                 ref={ref => this.button = ref}>Continue</ArrowButton>
                </div>
            </div>
            <br/>
        </div>
    }
}

export {SendMessage}