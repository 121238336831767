import {Button, Controller} from "./Components";
import SmartPaymentButtons, {PayPalSDKWrapper} from "react-smart-payment-buttons";
import React from "react";

class TestPayment extends Controller {
    render() {
        return <Button onClick={async event => await this.props.context.pay('test')}>Pay test!</Button>
    }
}

class StripePayment extends Controller {
    render() {
        return <Button onClick={async event => await this.props.context.checkout('stripe')}>Pay Stripe!</Button>
    }
}

class PaypalPayment extends Controller {
    render() {
        return <PayPalSDKWrapper clientId={this.props.params.client_id}>
            <SmartPaymentButtons
                createOrder={(data, actions) =>
                    actions.order.create({
                        purchase_units: [{
                            invoice_number: this.props.invoice.id,
                            amount: {
                                value: this.props.invoice.amount/100
                            },
                            description: this.props.invoice.description,
                        }]
                    })
                }
                onApprove={async (data, actions) => {
                    console.log(data);
                    await this.props.context.pay(this.props.params.type, data);
                }}
            />
        </PayPalSDKWrapper>
    }
}

export {TestPayment, PaypalPayment, StripePayment}