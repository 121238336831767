import React from "react";
import {CollapsibleItemList, H2, Invite, LoadController, MyComponent, TimeLeft,} from './Components'

const STATES = {
    init: 'Payment awaiting',
    sent: 'Pending',
    timeout: 'User didn\'t answer',
    accepted: 'User accepted',
    declined: 'User declined',
    recruiter_declined: 'Declined',
    recruiter_accepted: 'Sent interview invitation',
    interview_secured: 'Interview deposed',
};


class InviteItemBody extends MyComponent {
    constructor(props) {
        super(props);
        this.state = {show: true};
    }

    render() {
        const request = this.props.item;
        return <>
            <div className="padding5 display-linebreak">
                {/*<InviteStateInfo invite={invite} context={this.props.context}/>*/}
                {request.txt}
            </div>
        </>;
    }
}

const InviteSubHeader = props => {
    switch (props.item.state) {
        case Invite.INIT:
            return <>{STATES[props.item.state]}</>;
        case Invite.SENT:
            return <><TimeLeft time={new Date(props.item.expire_at)}/> {STATES[props.item.state]}</>;
        default:
            return <>{STATES[props.item.state]}</>;
    }
};

const ToArchive = props => <button className="btn btn-light btn-sm" onClick={event => {props.click(props.invite);}}><span className="fe fe-archive"/> Archive</button>;

const InviteHeader = props => <>
    <img src={props.item.userTo.avatar} alt="" className="avatar_ico"/>
    <button className="btn btn-sm btn-link" onClick={event => {props.context.redirect(`/request/${props.item.uuid}`)}}>
    <b>{props.item.userTo.name}</b></button>
    {props.item.subject}
    {/*<ToArchive invite={props.item} click={props.context.archive}/>*/}
</>;

class SentRequest extends MyComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return <li className="list-group-item">
            <img src={this.props.item.userTo.avatar} alt="" className="avatar_ico"/> &nbsp;
                <b>{this.props.item.userTo.name}</b>
            <a href={`/request/${this.props.item.uuid}`}>{this.props.item.subject}</a>
        </li>
    }
}

class SentView extends LoadController {
    constructor(props) {
        super(props);
        this.state = {loaded: false, activeInvites: [], allInvites: []};
        this.sendPayment = this.sendPayment.bind(this);
        this.archive = this.archive.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        document.title = 'Requests you have sent - FlashReply';
    }

    dataToLoad() {
        return {
            activeInvites: this.api.sentRequest(),
            allInvites: this.api.sentRequest(),
        };
    }

    sendPayment(invoice) {
        this.redirect(`/dashboard/invoice/${invoice.id}`);
    }

    archive(request) {
        this.removeInvite(request);
        this.api.archive_request(request).catch(this.handleError);
    }

    removeInvite(invite) {
        const index = this.state.allInvites.indexOf(invite);
        if (index > -1) {
            this.state.allInvites.splice(index, 1);
            this.setState({allInvites: this.state.allInvites});
        }
    }

    cancel(invite) {
        this.removeInvite(invite);
        this.api.recruiter_cancel(invite).catch(this.handleError);
    }

    async inviteInterview(invite) {
        const invoice = await this.api.inviteInterview(invite);
        this.redirect(`/dashboard/invoice/${invoice.id}`);
    }

    renderLoaded() {
        return <section className="pt-4 pt-md-11">
            <div className="container">
                <H2>Sent</H2>

                { (this.state.allInvites &&
                    <ul className="list-group">
                        {this.state.allInvites.map(item => <SentRequest item={item} context={this}/>)}
                    </ul> ) }
                { (!this.state.allInvites.length && this.loaded() && <div>You don't have active contacts at the moment. </div> ) }
            </div>
            <br/>
        </section>
    }
}

export {SentView};
