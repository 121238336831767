import React from "react";
import {
    Button,
    Card,
    Controller,
    LoadController,
    MyComponent,
    SpinnerBox, TimeLeft,
} from "./Components";
import {PaypalPayment, TestPayment, StripePayment} from "./Payments";

class ConnectionView extends LoadController {
    constructor(props) {
        super(props)
        this.connection_id = this.props.match.params.connection_id
    }

    dataToLoad() {
        return {connection: this.api.getModel('UserConnection', this.connection_id)}
    }

    renderLoaded() {
        const connection = this.state.connection

        if (!connection) {
            return <div>Can not load data</div>
        }

        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    <Card>
                        <p>{JSON.encode(connection)}</p>

                        <ul>
                            <li>
                                <Button onClick={this.requestSchedule}>Schedule a call - $5</Button>
                            </li>
                            <br/>
                            <li>
                                <Button onClick={this.requestImmediate}>Immediate call - $20</Button>
                            </li>
                        </ul>

                    </Card>
                </div>
            </div>
        </div>;
    }
}

class PaymentView extends MyComponent {
    render() {
        const request = this.props.request;
        const invoice = this.props.invoice;
        return <>
            <p>
                {request.userTo.name} requires <b>${Math.round(invoice.amount/100)}</b> application fee to review requests.
                Charged only if responded within 24 hours.

                {/*<a href="" data-toggle="collapse" data-target="#fee_details">FAQ</a>*/}
            </p>

            <ConfirmForm request={request} invoice={invoice} paymentProviders={this.props.paymentProviders} />
        </>
    }
}

class UserView extends MyComponent {
    render() {
        const user = this.props.user;
        return <div className="row" id="user_view">
            <div className="col-md-8 p-0">
                <div className="m-2">
                    <img src={user.avatar} alt={user.name} className="avatar_small img-thumbnail rounded-circle pull-left"/>
                    <div>
                        <b>{user.name}</b> <br/> <a href={"mailto:" + user.shadow_email} target="_blank">{user.shadow_email}</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

class ConfirmForm extends Controller {
    async pay(pay_type, data = {}) {
        this.setState({loading: true});
        await this.api.executePayment(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        window.location.reload(false);
    }

    async checkout(pay_type, data = {}) {
        this.setState({loading: true});
        data['success'] = window.location.protocol + '//' + window.location.host + `/request/${this.props.request.uuid}`
        data['cancel'] = window.location.protocol + '//' + window.location.host + `/request/${this.props.request.uuid}`

        const redirect = await this.api.checkout(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        this.redirect(redirect);
    }

    render() {
        return <>
            <div className="text-center">
                { this.props.paymentProviders.map( provider => {
                    switch (provider.type) {
                        case 'paypal':
                            return <PaypalPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'test':
                            return <TestPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'stripe':
                            return <StripePayment invoice={this.props.invoice} context={this} params={provider}/>;
                    }
                }) }
                {this.state.loading && <SpinnerBox/>}
            </div>
        </>;
    }
}

export {ConnectionView};
