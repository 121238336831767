import React, {useState} from "react";
import {ArrowButton, Controller, LoadController, UserContext,} from "./Components";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

class ProfileView extends LoadController {
    static contextType = UserContext

    constructor(props) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
    }

    dataToLoad() {
        return {user_full_profile: this.api.fullUserProfile()}
    }

    async onSubmit(event) {
        if (event) {
            event.preventDefault()
        }
        const user = this.context
        this.repo.update(user.id)
        await this.api.updateProfile(user.id, {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone: this.state.phone,
        })
        this.button.stopLoading()
    }

    render() {
        const user = this.state.user_full_profile
        if (!user) {
            return <></>
        }

        return <div className="section">
            <div className="container">
                <br/>
                <form onSubmit={this.onSubmit}>
                <h2>
                    <a href={"/user/" + (user.path || user.id)}>{user.name}</a>
                </h2>

                    <a href={"/connection_param"}>Edit connections</a>


                    <hr/>

                    <div className="form-label-group">
                        <input placeholder="First Name" className="form-control form-control-flush" defaultValue={user.first_name} id="first_name" onChange={this.updateState('first_name')}/>
                        <label htmlFor="first_name">First Name</label>
                    </div>

                    <div className="form-label-group">
                        <input placeholder="Last Name" className="form-control form-control-flush" defaultValue={user.last_name} id="last_name" onChange={this.updateState('last_name')}/>
                        <label htmlFor="last_name">Last Name</label>
                    </div>

                    <div className="form-label-group">
                        <input placeholder="Email" className="form-control form-control-flush" defaultValue={user.email} id="email" disabled/>
                        <label htmlFor="email">Email</label>
                    </div>

                    <div className="form-label-group">
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={user.phone}
                            onChange={value => this.setState({phone: value})}
                        />
                        {!user.phone_validated_at && <div>
                            Validate phone
                        </div>}
                    </div>

                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">@</div>
                        </div>
                        <input type="text" className="form-control" id="username" placeholder="Username" defaultValue={user.path}  onChange={event => this.setState({path: event.target.value})}/>
                    </div>

                    <ArrowButton onclick={this.onSubmit} ref={ref => this.button = ref}>Save</ArrowButton>
                </form>
            </div>
        </div>
    }
}

export {ProfileView}
