import React from "react";
import {
    Card,
    CollapsableList,
    Controller,
    H1,
    H2,
    List,
    LoadController,
    MyComponent,
    Spinner,
    TimeLeft
} from "./Components";
import PaymentView from "./PaymentView";
import {Message} from "./Message";



class Dialog extends MyComponent {
    render() {
        return <Card>
            {this.props.children.status}
            <PaymentView/>
        </Card>
    }
}

class MessagesView extends LoadController {
    dataToLoad() {
        return {dialog: this.api.getDialog(this.path, this.param('dialog_created'))};
    }

    constructor(props) {
        super(props);

        this.path = this.props.match.params.path;
        this.state = {
            text: "",
            messages: [],
        }

        this.submit = this.submit.bind(this);
    }

    async submit(e) {
        e.preventDefault()
        const messages = this.state.messages;
        messages.push({
            from_id: this.props.user.id,
            text: this.state.text,
            status: 'pending',
        });
        this.setState({messages: messages, text: ""})
        const result = await this.api.sendMessage(this.path, {message: this.state.text, dialog_created: this.param('dialog_created')})
        console.log(result)
    }

    renderLoaded() {
        const messages = this.state.dialog.messages.concat(this.state.messages)

        return <div className="container">
            <div className="col-md-6 col-sm-12">

            <Dialog>
                {this.state.dialog.dialog}
            </Dialog>

            <H1>Messages</H1>

            <ul className="list-group">
            {messages.map(message => <Message message={message} user={this.props.user} key={message.created_at}/>)}
            </ul>

            <form className="card" onSubmit={this.submit}>
                <div className="card-body">
                    <div className="form-group">
                        <input className="form-control" onChange={e => this.setState({text: e.target.value})} value={this.state.text}/>
                    </div>
                    <div className="mb-3">
                        <input type="submit" className="btn btn-success"/>
                    </div>
                </div>
            </form>
            </div>
        </div>
    }
}


export {MessagesView};
