import React from "react";
import {
    Button,
    Card,
    Controller,
    LoadController, MyComponent,
    Spinner,
    SpinnerBox,
    TimeLeft, UserContext
} from "./Components";
import {PaypalPayment, StripePayment, TestPayment} from "./Payments";
import {DialogMessages} from "./DialogMessages";


class InvoiceView extends LoadController {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.invoice_id = this.param('id')
        this.token = this.param('token')
        if (this.token) {
            this.repo.setToken(this.token).then(console.log)
        } else {
            this.token = ""
        }

        this.state = {confirm: false, messages: []}
    }

    dataToLoad() {
        return {
            invoice: this.api.loadInvoice(this.invoice_id),
            paymentProviders: this.api.loadPayProviders(this.invoice_id)
        }
    }

    preloader = () => (
        {
            "ph-col-2": 'ph-avatar', 'ph-col-10': '',
            'ph-col-12': [
                {
                    'ph-row': ['ph-col-4 big', "ph-col-8 empty big"]
                },
                {
                    'ph-row': ["ph-col-2 big", "ph-col-12", "ph-col-12", "ph-col-12"]
                }
            ]
        }
    );

    renderLoaded() {
        console.log(this.state)

        const user = this.context
        const invoice = this.state.invoice

        if (!user) {
            return <div>Loading error, please try again later.</div>
        }

        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    <h2>Invoice {invoice.id}, {new Date(invoice.created_at).toLocaleString()}</h2>
                    <Card>
                        <p>{invoice.description}</p>
                        <b>${Math.round(invoice.amount)/100}</b>
                    </Card>
                    {invoice.state === 'init' && <ConfirmForm invoice={invoice} paymentProviders={this.state.paymentProviders} />}
                </div>
            </div>
        </div>
    }
}

class ConfirmForm extends Controller {
    async pay(pay_type, data = {}) {
        this.setState({loading: true});
        await this.api.executePayment(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        window.location.reload(false);
    }

    async checkout(pay_type, data = {}) {
        this.setState({loading: true});
        data['success'] = window.location.protocol + '//' + window.location.host + `/dialog/${this.props.request.id}`
        data['cancel'] = window.location.protocol + '//' + window.location.host + `/dialog/${this.props.request.id}`

        const redirect = await this.api.checkout(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        this.redirect(redirect);
    }

    render() {
        return <>
            <div className="text-center">
                { this.props.paymentProviders.map( provider => {
                    switch (provider.type) {
                        case 'paypal':
                            return <PaypalPayment invoice={this.props.invoice} context={this} params={provider} key={provider.type}/>;
                        case 'test':
                            return <TestPayment invoice={this.props.invoice} context={this} params={provider} key={provider.type}/>;
                        case 'stripe':
                            return <StripePayment invoice={this.props.invoice} context={this} params={provider} key={provider.type}/>;
                    }
                }) }
                {this.state.loading && <SpinnerBox/>}
            </div>
        </>;
    }
}

export {InvoiceView}
