import {Button, Card, Controller} from "./Components";
import React from "react";
import {SendMessage} from "./SendMessage";

class Connections extends Controller {
    render() {
        if (!this.props.user) {
            return <></>
        }

        return Object.keys(this.props.connectionsParams).map(connectionType => {
            if (!this.props.connectionsParams[connectionType].allow) {
                return <></>
            }

            return <ConnectionParamView user={this.props.user} connectionType={connectionType} connectionParam={this.props.connectionsParams[connectionType]}/>
        })
    }
}

class ConnectionParamView extends Controller {
    render() {
        const connectionType = this.props.connectionType
        const contactType = this.props.connectionParam

        switch (connectionType) {
            case 'email': return <EmailConnection data={contactType}/>
            case 'immediate_call': return <InstantCallConnection data={contactType} user={this.props.user} requests={this.props.connectionRequests}/>
            case 'scheduled_call': return <ScheduledCallConnection data={contactType} user={this.props.user} requests={this.props.connectionRequests}/>
            case 'regular_message': return <RegularMessageConnection data={contactType} user={this.props.user} requests={this.props.connectionRequests}/>
            case 'urgent_message': return <FastMessageConnection data={contactType} user={this.props.user} requests={this.props.connectionRequests}/>
            case 'priority_message': return <PriorityMessageConnection data={contactType} user={this.props.user} requests={this.props.connectionRequests}/>
            case 'announced_call': return <AnnouncedCallConnection data={contactType} user={this.props.user} requests={this.props.connectionRequests}/>
            default: return <></>
        }
    }
}

class AbstractConnection extends Controller {
    constructor(props) {
        super();
        this.state = {enabled: false}
        this.initiateConnection = this.initiateConnection.bind(this)
        this.startCall = this.startCall.bind(this)
    }

    get title() {
        return this.props.type
    }

    get description() {
        return ''
    }

    get type() {
        throw new Error('Not implemented')
    }

    get action() {
        throw new Error('Not implemented')
    }

    initiateConnection() {
        this.setState({enablePrepare: true})
    }

    async startCall() {
        return await this.api.orderConnection(this.props.user.id, this.type)
    }

    prepare() {
        return <></>
    }

    render() {
        const price = this.props.data.require_fee ? "$" + this.props.data.fee / 100 : "Connect"

        if (!this.props.data.allow) {
            return <></>
        }

        return <Card>
            {JSON.stringify(this.props.data)}
            <h4>{this.title}</h4>
            <p>{this.description}</p>
            <Button className="btn-primary-desat" onClick={this.initiateConnection}>{price}</Button>
            {this.state.enablePrepare && this.prepare()}

            Requests:
            { (this.props.requests || []).length }
        </Card>
    }
}

class EmailConnection extends AbstractConnection {
    get title() {
        return 'Email'
    }

    get type() {
        return 'email'
    }

    get description() {
        return 'Send an Email'
    }

    get action() {
        return 'send_email'
    }
}

class CallConnection extends AbstractConnection {
    get action() {
        return 'call'
    }

    prepareParams() {
        return <></>
    }

    prepare() {
        return <div>
            { this.prepareParams() }
            <Button className="btn-success" onClick={this.startCall}>Start a call</Button>
        </div>
    }
}

class MessageConnection extends AbstractConnection {
    get action() {
        return 'message'
    }

    prepare() {
        return <SendMessage sendInvitation={this.sendInvitation}/>
    }
}

class InstantCallConnection extends CallConnection {
    get title() {
        return 'Call'
    }

    get type() {
        return 'immediate_call'
    }

    get description() {
        return 'Enable an instant call'
    }
}

class AnnouncedCallConnection extends CallConnection {
    get title() {
        return 'Announced Call'
    }

    get type() {
        return 'announced_call'
    }

    get description() {
        return 'Provide a reason of you call'
    }

    prepareParams() {
        return <div>
            <label htmlFor="reason">Reason</label>
            <textarea className="form-control" id="reason" rows="10"
                      onChange={event => this.setState({reason: event.target.value})}
                      value={this.state.reason}/>
        </div>

    }
}

class ScheduledCallConnection extends CallConnection {
    get title() {
        return 'Schedule a Call'
    }

    get description() {
        return 'You can chose an available slot (date and time) to call. Nearest available slot is at 12/10/2024 at 12pm'
    }

    get type() {
        return 'scheduled_call'
    }

    prepareParams() {
        return <div>
            <label htmlFor="date">Date</label>
            <input type="date" className="form-control" id="date"
                   onChange={event => this.setState({date: event.target.value})
                     } value={this.state.date}/>
            <label htmlFor="time">Time</label>
        </div>
    }
}

class RegularMessageConnection extends MessageConnection {
    get title() {
        return 'Regular reply (24 hours)'
    }

    get description() {
        return 'Start a dialog, receive first reply within 24 hours.'
    }
}

class FastMessageConnection extends MessageConnection {
    get title() {
        return 'Emergency reply (1 min)'
    }

    get description() {
        return 'Start a dialog, receive first reply within 1 minutes.'
    }
}

class PriorityMessageConnection extends MessageConnection {
    get title() {
        return 'Priority message (5 min)'
    }

    get description() {
        return 'Start a dialog, receive first reply within 5 minutes.'
    }
}

export {ConnectionParamView, Connections, EmailConnection, InstantCallConnection, AnnouncedCallConnection, ScheduledCallConnection, MessageConnection, FastMessageConnection, PriorityMessageConnection}
