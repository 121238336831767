import React from "react";
import {
    Button,
    Card,
    Controller,
    LoadController,
    MyComponent,
    SpinnerBox, TimeLeft,
} from "./Components";
import {PaypalPayment, TestPayment, StripePayment} from "./Payments";

class CallRequestView extends LoadController {
    constructor(props) {
        super(props);
        this.user_id = this.props.match.params.user_id;
        this.token = this.props.match.params.token;
        this.requestSchedule = this.requestSchedule.bind(this)
        this.requestImmediate = this.requestImmediate.bind(this)

        if (this.token) {
            localStorage.setItem('token', this.token);
        }
    }

    dataToLoad() {
        return {recipient: this.api.loadUser(this.user_id)};
    }

    async requestSchedule() {
        const result = await this.api.orderCallScheduled(this.state.recipient.id)
        this.redirect(`/invoice/${result.invoice_id}`)
    }

    async requestImmediate() {
        const result = await this.api.orderCallImmediate(this.state.recipient.id)
        this.redirect(`/invoice/${result.invoice_id}`)
    }

    renderLoaded() {
        const recipient = this.state.recipient

        if (!recipient) {
            return <div>Can not load data</div>
        }

        document.title = `Select an option to call ${recipient.name}`;

        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    <UserView user={recipient}/>

                    <Card>
                        <p>Welcome to FlashReply! You’re about to connect with {recipient.name} in a way that respects their time and preferences.</p>

                        <ul>
                            <li>
                                <Button onClick={this.requestSchedule}>Schedule a call - $5</Button>
                            </li>
                            <br/>
                            <li>
                                <Button onClick={this.requestImmediate}>Immediate call - $20</Button>
                            </li>
                        </ul>

                        <strong>Understanding the Fee System</strong>
                        <ul>
                            <li>No Charge for Unanswered Calls: Fees are charged only when [User's Name] successfully connects with you, ensuring fair compensation for their time.</li>
                            <li>Direct Support to [User's Name]: These fees, set by [User's Name], directly reward them for making themselves available.</li>
                            <li>Prioritizing Your Communication: Your contribution helps your call or message stand out, reflecting the value you place on connecting with [User's Name].</li>
                            <li>Respecting Boundaries: This system honors [User's Name]'s time, promoting a balanced and respectful communication environment.</li>
                        </ul>
                    </Card>
                </div>
            </div>
        </div>;
    }
}

class PaymentView extends MyComponent {
    render() {
        const request = this.props.request;
        const invoice = this.props.invoice;
        return <>
            <p>
                {request.userTo.name} requires <b>${Math.round(invoice.amount/100)}</b> application fee to review requests.
                Charged only if responded within 24 hours.

                {/*<a href="" data-toggle="collapse" data-target="#fee_details">FAQ</a>*/}
            </p>

            <ConfirmForm request={request} invoice={invoice} paymentProviders={this.props.paymentProviders} />
        </>
    }
}

class UserView extends MyComponent {
    render() {
        const user = this.props.user;
        return <div className="row" id="user_view">
            <div className="col-md-8 p-0">
                <div className="m-2">
                    <img src={user.avatar} alt={user.name} className="avatar_small img-thumbnail rounded-circle pull-left"/>
                    <div>
                        <b>{user.name}</b> <br/> <a href={"mailto:" + user.shadow_email} target="_blank">{user.shadow_email}</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

class ConfirmForm extends Controller {
    async pay(pay_type, data = {}) {
        this.setState({loading: true});
        await this.api.executePayment(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        window.location.reload(false);
    }

    async checkout(pay_type, data = {}) {
        this.setState({loading: true});
        data['success'] = window.location.protocol + '//' + window.location.host + `/request/${this.props.request.uuid}`
        data['cancel'] = window.location.protocol + '//' + window.location.host + `/request/${this.props.request.uuid}`

        const redirect = await this.api.checkout(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        this.redirect(redirect);
    }

    render() {
        return <>
            <div className="text-center">
                { this.props.paymentProviders.map( provider => {
                    switch (provider.type) {
                        case 'paypal':
                            return <PaypalPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'test':
                            return <TestPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'stripe':
                            return <StripePayment invoice={this.props.invoice} context={this} params={provider}/>;
                    }
                }) }
                {this.state.loading && <SpinnerBox/>}
            </div>
        </>;
    }
}

export {CallRequestView};
