import React from 'react';

const Item = props => <li className="nav-item ">
    <a className="nav-link href" id="navbarDocumentation" href={(typeof props.href === "function") ? null : props.href} onClick={ event => {if (typeof props.href === "function") {props.href()}}} aria-haspopup="true"
       aria-expanded="false">
        { props.children }
    </a>
</li>;

class Items extends React.Component {
    render() {
        return <ul className="navbar-nav ml-auto">
            { Object.entries(this.props.items).map(item => <Item href={item[1]} key={item[1]}>{item[0]}</Item> ) }
        </ul>
    }
}

class Menu extends React.Component {
    getMenu() {
        let items = {
        };

        if (this.props.user) {
            items['Dashboard'] = "./my";
            items['Search'] = "./search";
            items['Profile'] = "./profile";
            items['Balance'] = "./balance";
            items['Logout'] = "./logout";
        } else {
            items['Sign Up'] = "./signup";
        }

        return items;
    }

    render() {
        return <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom container">
            <link rel="stylesheet"
                  href="https://fonts.googleapis.com/css?family=Oswald"/>
            <a className="navbar-brand" href="./">
                <img src="/logo-192x192.png" alt="FlashReply" width="24" className="top-logo"/> <span
                className="brand">FlashReply</span>
            </a>

            {this.props.user && <span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse">

                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <i className="fe fe-x"></i>
                    </button>

                    <Items items={this.getMenu()}/>
                </div>
                </span>}
        </nav>
    }
}

export default Menu;