import React from "react";
import {
    Button,
    Card,
    Controller,
    LoadController, MyComponent,
    Spinner,
    SpinnerBox,
    TimeLeft, UserContext
} from "./Components";
import {PaypalPayment, StripePayment, TestPayment} from "./Payments";
import {DialogMessages} from "./DialogMessages";


class DialogView extends LoadController {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.id = this.param('id')
        this.token = this.param('token')
        if (this.token) {
            this.repo.setToken(this.token).then(console.log)
        } else {
            this.token = ""
        }
        this.resendLink = this.resendLink.bind(this)
        this.showMore = this.showMore.bind(this)
        this.submit = this.submit.bind(this)
        this.onAnswer = this.onAnswer.bind(this)
        this.state = {confirm: false, messages: []}
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.match !== nextProps.match || this.state !== nextState;
    }

    dataToLoad() {
        return {data: this.api.getDialogToken(this.id, this.token), paymentProviders: this.api.loadPayProviders(1), messages: this.api.getMessages(this.id)};
    }

    async resendLink() {
        this.setState({loading: true});
        await this.api.refreshRequest(this.request);
        this.setState({resent: true, loading: false})
    }

    handleAuthError(error) {
        if (this.state.resent) {
            return <div className="container col-lg-5 col-sm-10">
                <h1>Your link has expired</h1>
                <p>We have sent your new link to your email.</p>
            </div>;
        }

        return <div className="container col-lg-5 col-sm-10">
            <h1>Your link has expired</h1>
            <Button onClick={this.resendLink}>Resend the link</Button>
            { this.state.loading && <SpinnerBox/> }
        </div>;
    }

    async submit(e) {
        e.preventDefault()
        const messages = this.state.messages;
        messages.push({
            author_id: this.props.user.id,
            text: this.state.text,
            status: 'pending',
        });
        this.setState({messages: messages, text: ""})
        const result = await this.api.sendMessage(this.param('id'), this.state.text)
        console.log(result)
    }

    async onAnswer(text) {
        const result = await this.api.sendMessage(this.param('id'), text)
        console.log(result)
    }

    preloader = () => (
        {
            "ph-col-2": 'ph-avatar', 'ph-col-10': '',
            'ph-col-12': [
                {
                    'ph-row': ['ph-col-4 big', "ph-col-8 empty big"]
                },
                {
                    'ph-row': ["ph-col-2 big", "ph-col-12", "ph-col-12", "ph-col-12"]
                }
            ]
        }
    );

    cancel() {

    }

    async componentDidMount(){
        await super.componentDidMount();
        if (this.textElement) {
            this.setState({clipped: this.textElement.scrollHeight > this.textElement.clientHeight});
        }
    }

    showMore() {
        this.setState({showMore: true});
    }

    renderLoaded() {
        console.log(this.state)
        const dialog = this.state.data.dialog
        const user = this.context

        if (!user) {
            return <div>Loading error, please try again later.</div>
        }

        const other_user_id = dialog.author_id === user.id ? dialog.recipient_id : dialog.author_id

        document.title = `Confirm your request to ${dialog.id}`;

        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    <UserView user_id={other_user_id}/>

                    <Card>
                        <div className={ this.state.showMore ? "" : "clipped"} ref={ el => this.textElement = el}>
                            <b>{dialog.subject}</b>
                        </div>
                        <DialogMessages messages={this.state.messages}/>

                        {/*<button className="btn btn-xs btn-link" onClick={_ => this.setState({add_message: true})}>Add message</button>*/}
                        {/*<button className="btn btn-xs btn-link" onClick={this.cancel}>Cancel</button>*/}

                        {this.state.add_message && <form className="card" onSubmit={this.submit}>
                            <div className="card-body">
                                <div className="form-group">
                                    <input className="form-control" onChange={e => this.setState({text: e.target.value})} value={this.state.text}/>
                                </div>
                                <div className="mb-3">
                                    <input type="submit" className="btn btn-success"/>
                                </div>
                            </div>
                        </form>}

                        { this.state.clipped && !this.state.showMore && <button className="btn btn-sm btn-secondary-soft m-2" onClick={this.showMore}>More</button> }
                    </Card>

                    <br/>
                    {dialog.author_id === user.id && <RequestState request={this.state.data.dialog} invoice={this.state.data.invoice} paymentProviders={this.state.paymentProviders} cancel={this.cancel} />}
                    {dialog.recipient_id === user.id && <IncomingState dialog={this.state.data.dialog}/>}

                    {/*<AddMessage onAnswer={this.onAnswer} />*/}
                </div>
            </div>
        </div>;
    }
}

class AddMessage extends LoadController {
    render()
    {
        return <div>
            <form className="card" onSubmit={e => {e.preventDefault(); this.props.onAnswer(this.state.text)}}>
                <div className="card-body">
                    <div className="form-group">
                        <input className="form-control" onChange={e => this.setState({text: e.target.value})} value={this.state.text}/>
                    </div>
                    <div className="mb-3">
                        <input type="submit" className="btn btn-success"/>
                    </div>
                </div>
            </form>
        </div>
    }
}

class IncomingState extends LoadController {
    render()
    {
        return <div>
            <h2>Please answer</h2>
            <div>
                Time: <TimeLeft time={new Date(this.props.dialog.expire_at)}/><br/>
                Status: {this.props.dialog.status}
            </div>
        </div>
    }
}

const RequestState = props => {
    const request = props.request;

    switch (request.status) {
        case 'pending':
            return <PaymentView request={request} invoice={props.invoice} paymentProviders={props.paymentProviders}
                                cancel={props.cancel}/>;
        case 'sent':
            return <div className="alert alert-primary-desat">Your request is awaiting response, <TimeLeft time={new Date(request.expire_at)}/></div>;
        case 'replied':
            return <></>
        case 'timeout':
            return <div className="alert alert-secondary">User did not respond in time, your deposit has been refunded.</div>;
        default:
            return <div className="alert alert-secondary">{request.state}</div>;
    }
};

class PaymentView extends MyComponent {
    render() {
        const request = this.props.request;
        const invoice = this.props.invoice;
        return <>
            <p>
                {request.user_to} requires <b>${Math.round(invoice.amount)/100}</b> application fee to review requests.
                Charged only if responded within 24 hours.

                {/*<a href="" data-toggle="collapse" data-target="#fee_details">FAQ</a>*/}
            </p>

            <ConfirmForm request={request} invoice={invoice} paymentProviders={this.props.paymentProviders} />
        </>
    }
}

class UserView extends LoadController {
    dataToLoad() {
        return {user: this.repo.get('user', this.props.user_id)}
    }

    renderLoaded() {
        const user = this.state.user;
        if (!user) {
            return <Spinner/>
        }
        return <div className="row" id="user_view">
            <div className="col-md-8 p-0">
                <div className="m-2">
                    <a href={`/user/${user.path}`}>
                    {/*<img src={user.avatar || 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} alt={user.name} className="avatar_small img-thumbnail rounded-circle pull-left"/>*/}
                    <div>
                        <b>{user.name}</b> <br/> <a href={"mailto:" + user.shadow_email} target="_blank">{user.shadow_email}</a>
                    </div>
                    </a>
                    <Verification list={user.verification || []} />
                </div>
            </div>
            {/*<div className="col-md-4 pull-right  p-0">*/}
            {/*    <button className="btn btn-secondary-soft btn-sm m-2" data-toggle="collapse" data-target="#user_details">Details</button>*/}
            {/*</div>*/}


            {/*<div className="collapse card card-border border-primary shadow-light-lg mb-6 mb-md-8 aos-init aos-animate"*/}
            {/*     id="user_details"*/}
            {/*     data-aos="fade-up"*/}
            {/*     data-parent="#user_view"*/}
            {/*>*/}
            {/*    <div className="card-body">*/}
            {/*        <div>*/}
            {/*            <div className="list-group list-group-flush">*/}
            {/*                <a className="list-group-item text-reset text-decoration-none" href="#!">*/}
            {/*                    <p className="mb-1 display-linebreak">*/}
            {/*                        {this.state.user.about}*/}
            {/*                        About*/}
            {/*                    </p>*/}

            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}

        </div>
    }
}

class Verification extends Controller {
    render() {
        return <ul>
            { this.props.list.map(element => <VerificationElement verification={element}/> ) }
        </ul>
    }
}

class VerificationElement extends Controller {
    render() {
        return <>
            { this.props.verification.type }
        </>
    }
}

class ConfirmForm extends Controller {
    async pay(pay_type, data = {}) {
        this.setState({loading: true});
        await this.api.executePayment(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        window.location.reload(false);
    }

    async checkout(pay_type, data = {}) {
        this.setState({loading: true});
        data['success'] = window.location.protocol + '//' + window.location.host + `/dialog/${this.props.request.id}`
        data['cancel'] = window.location.protocol + '//' + window.location.host + `/dialog/${this.props.request.id}`

        const redirect = await this.api.checkout(this.props.invoice.id, pay_type, data);
        this.setState({loading: false});
        this.redirect(redirect);
    }

    render() {
        return <>
            <div className="text-center">
                { this.props.paymentProviders.map( provider => {
                    switch (provider.type) {
                        case 'paypal':
                            return <PaypalPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'test':
                            return <TestPayment invoice={this.props.invoice} context={this} params={provider}/>;
                        case 'stripe':
                            return <StripePayment invoice={this.props.invoice} context={this} params={provider}/>;
                    }
                }) }
                {this.state.loading && <SpinnerBox/>}
            </div>
        </>;
    }
}



export {DialogView};
