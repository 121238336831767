import React from 'react';
import Typed from 'typed.js';

class HomeView extends React.Component {
    render() {
        return <>
            <Top/>
            {/*<Features/>*/}
            <ImageSection2/>
            {/*<FeaturesDark/>*/}
            {/*<ImageSection/>*/}
            </>
    }
}

const ImageSection2 = props => <section className="py-8 py-md-11 bg-light">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-md-5 col-lg-6 order-md-2">

                
                <div className="w-md-150 w-lg-130 mb-6 mb-md-0">
                    <div className="device-combo device-combo-iphonex-macbook">

                        
                        <div className="device device-iphonex">
                            <img src="assets/img/screenshots/mobile/jobs.jpg" className="device-screen" alt="..."/>
                                <img src="assets/img/devices/iphonex.svg" className="img-fluid" alt="..."/>
                        </div>

                        
                        <div className="device device-macbook">
                            <img src="assets/img/screenshots/desktop/jobs.jpg" className="device-screen" alt="..."/>
                                <img src="assets/img/devices/macbook.svg" className="img-fluid" alt="..."/>
                        </div>

                    </div>
                </div>

            </div>
            <div className="col-12 col-md-7 col-lg-6 order-md-1">

                
                <h2>
                    Save your time <br/>
                    <span className="text-primary">when reaching out</span>.
                </h2>

                
                <p className="font-size-lg text-muted mb-6">
                    Be sure that your messages will never be ignored.
                </p>

                
                <div className="d-flex">

                    
                    <div className="icon text-primary">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" >
                            <g id="Stockholm-icons-/-General-/-Clipboard" stroke="none" stroke-width="1" fill="none"
                               fill-rule="evenodd">
                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                <path
                                    d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                                    id="Combined-Shape" fill="#335EEA" opacity="0.3"></path>
                                <path
                                    d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                                    id="Combined-Shape" fill="#335EEA"></path>
                                <rect id="Rectangle-152" fill="#335EEA" opacity="0.3" x="7" y="10" width="5" height="2"
                                      rx="1"></rect>
                                <rect id="Rectangle-152-Copy" fill="#335EEA" opacity="0.3" x="7" y="14" width="9"
                                      height="2" rx="1"></rect>
                            </g>
                        </svg>
                    </div>

                    <div className="ml-5">
                        <h4 className="mb-1">
                            High response rate
                        </h4>

                        <p className="text-muted mb-5">
                            86% of recipients respond within 24 hours.
                        </p>

                    </div>

                </div>
            </div>
        </div>
        
    </div>
    
</section>

const ImageSection = props => <section className="py-8 py-md-11 border-bottom">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-12 col-md-6 order-md-2">

                
                <img src="assets/img/illustrations/illustration-1.png" alt="..." className="img-fluid mb-6 mb-md-0"/>

            </div>
            <div className="col-12 col-md-6 order-md-1">

                
                <span className="badge badge-pill badge-primary-soft mb-3">
              <span className="h6 text-uppercase">Scalable</span>
            </span>

                
                <h2>
                    Flexible office space means <br/>
                    <span className="text-primary">growing your team is easy</span>.
                </h2>

                
                <p className="font-size-lg text-gray-700 mb-6 mb-md-6">
                    Rather than worrying about switching offices every couple years, you can instead stay in the same
                    location and grow-up from your shared coworking space to an office that takes up an entire floor.
                </p>

                
                <div className="row">
                    <div className="col-12 col-lg-6">

                        
                        <div className="d-flex">

                            
                            <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                                <i className="fe fe-check"></i>
                            </div>

                            
                            <p>
                                Add space anytime
                            </p>

                        </div>

                        
                        <div className="d-flex">

                            
                            <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                                <i className="fe fe-check"></i>
                            </div>

                            <p className="mb-lg-0">
                                Group discounts
                            </p>

                        </div>

                    </div>
                    <div className="col-12 col-lg-6">

                        
                        <div className="d-flex">

                            
                            <span className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <i className="fe fe-check"></i>
                  </span>

                            
                            <p>
                                Infinite flexibility
                            </p>

                        </div>

                        
                        <div className="d-flex">

                            
                            <div className="badge badge-rounded-circle badge-primary-soft mr-1 mr-4">
                                <i className="fe fe-check"></i>
                            </div>

                            
                            <p className="mb-0">
                                Snacks included
                            </p>

                        </div>

                    </div>
                </div>
                

            </div>
        </div>
        
    </div>
    
</section>;

class Top extends React.Component {
    componentDidMount() {
        this.typed = new Typed(this.el, {
            strings: ['to communicate', 'to recruit', 'to make connections', 'to send inquiries', 'reaching out'],
            typeSpeed: 50,
            backSpeed: 50,
            startDelay: 200,
            backDelay: 1000,
            loop: true,
        });
    }

    render() {
        return <section className="pt-4 pt-md-11">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-5 col-lg-6 order-md-2">

                        <img src="assets/img/illustrations/illustration-2.png"
                             className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0 aos-init aos-animate" alt="..."
                             data-aos="fade-up" data-aos-delay="100"/>

                    </div>
                    <div className="col-12 col-md-7 col-lg-6 order-md-1 aos-init aos-animate" data-aos="fade-up">

                        <h1 className="display-3 text-center text-md-left">
                            <span className="text-primary">FlashReply</span> <br/>
                            Better way<br/><span ref={(el) => { this.el = el; }} style={{whiteSpace: "nowrap"}} className="text-success"></span>
                        </h1>

                        <p className="lead text-center text-md-left text-muted mb-6 mb-lg-8">
                            Reach out to recipients and get responses within 24 hours.
                        </p>

                        {/*<div className="text-center text-md-left">*/}
                        {/*    <a href="overview.html" className="btn btn-primary shadow lift mr-1">*/}
                        {/*        Get Started <i className="fe fe-arrow-right d-none d-md-inline ml-3"></i>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </section>;
    }
}

const Features = props => <section className="py-8 py-md-11 border-bottom">
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up">

                <div className="icon text-primary mb-3">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         >
                        <title>Stockholm-icons / General / Settings-1</title>
                        <desc>Created with Sketch.</desc>
                        <g id="Stockholm-icons-/-General-/-Settings-1" stroke="none" stroke-width="1" fill="none"
                           fill-rule="evenodd">
                            <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                            <path
                                d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                                id="Combined-Shape" fill="#335EEA"></path>
                            <path
                                d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                                id="Combined-Shape" fill="#335EEA" opacity="0.3"></path>
                        </g>
                    </svg>
                </div>

                <h3>
                    Built for developers
                </h3>

                <p className="text-muted mb-6 mb-md-0">
                    Landkit is built to make your life easier. Variables, build tooling, documentation, and reusable
                    components.
                </p>

            </div>
            <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">

                <div className="icon text-primary mb-3">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         >
                        <title>Stockholm-icons / Layout / Layout-arrange</title>
                        <desc>Created with Sketch.</desc>
                        <g id="Stockholm-icons-/-Layout-/-Layout-arrange" stroke="none" stroke-width="1" fill="none"
                           fill-rule="evenodd">
                            <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                            <path
                                d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z"
                                id="Combined-Shape" fill="#335EEA"></path>
                            <path
                                d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z"
                                id="Combined-Shape" fill="#335EEA" opacity="0.3"></path>
                        </g>
                    </svg>
                </div>

                <h3>
                    Designed to be modern
                </h3>

                <p className="text-muted mb-6 mb-md-0">
                    Designed with the latest design trends in mind. Landkit feels modern, minimal, and beautiful.
                </p>

            </div>
            <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">

                <div className="icon text-primary mb-3">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         >
                        <title>Stockholm-icons / Code / Code</title>
                        <desc>Created with Sketch.</desc>
                        <g id="Stockholm-icons-/-Code-/-Code" stroke="none" stroke-width="1" fill="none"
                           fill-rule="evenodd">
                            <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                            <path
                                d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z"
                                id="Combined-Shape" fill="#335EEA"></path>
                            <rect id="Rectangle-28" fill="#335EEA" opacity="0.3"
                                  transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) "
                                  x="11" y="4" width="2" height="16" rx="1"></rect>
                        </g>
                    </svg>
                </div>

                <h3>
                    Documentation for everything
                </h3>

                <p className="text-muted mb-0">
                    We've written extensive documentation for components and tools, so you never have to reverse
                    engineer anything.
                </p>

            </div>
        </div>
    </div>
</section>;

const FeaturesDark = props => <>
    <div className="position-relative">
    <div className="shape shape-bottom shape-fluid-x svg-shim text-dark">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
    <section className="bg-dark">
        <div className="container pt-8 pt-md-11">
            <div className="row">
                <div className="col-12 col-md-6">

                    
                    <div className="d-flex">

                        
                        <div className="badge badge-lg badge-rounded-circle badge-success">
                            <span>?</span>
                        </div>

                        <div className="ml-5">

                            
                            <h4 className="text-white">
                                Can I use Landkit for my clients?
                            </h4>

                            
                            <p className="text-muted mb-6 mb-md-8">
                                Absolutely. The Bootstrap Themes license allows you to build a websites for personal use
                                or for a client.
                            </p>

                        </div>

                    </div>

                    
                    <div className="d-flex">

                        
                        <div className="badge badge-lg badge-rounded-circle badge-success">
                            <span>?</span>
                        </div>

                        <div className="ml-5">

                            
                            <h4 className="text-white">
                                Do I get free updates?
                            </h4>

                            
                            <p className="text-muted mb-6 mb-md-0">
                                Yes. We update all of our themes with each Bootstrap update, plus are constantly adding
                                new components, pages, and features to our themes.
                            </p>

                        </div>

                    </div>

                </div>
                <div className="col-12 col-md-6">

                    
                    <div className="d-flex">

                        
                        <div className="badge badge-lg badge-rounded-circle badge-success">
                            <span>?</span>
                        </div>

                        <div className="ml-5">

                            
                            <h4 className="text-white">
                                Is there a money back guarantee?
                            </h4>

                            
                            <p className="text-muted mb-6 mb-md-8">
                                Yup! Bootstrap Themes come with a satisfaction guarantee. Submit a return and get your
                                money back.
                            </p>

                        </div>

                    </div>

                    
                    <div className="d-flex">

                        
                        <div className="badge badge-lg badge-rounded-circle badge-success">
                            <span>?</span>
                        </div>

                        <div className="ml-5">

                            
                            <h4 className="text-white">
                                Does it work with Rails? React? Laravel?
                            </h4>

                            
                            <p className="text-muted mb-6 mb-md-0">
                                Yes. Landkit has basic CSS/JS files you can include. If you want to enable deeper
                                customization, you can integrate it into your assets pipeline or build processes.
                            </p>

                        </div>

                    </div>

                </div>
            </div>
            
        </div>
        
    </section>
    <section className="py-8 py-md-11 bg-dark">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 text-center">

                    
                    <span className="badge badge-pill badge-gray-700-soft mb-4">
              <span className="h6 font-weight-bold text-uppercase">Get started</span>
            </span>

                    
                    <h1 className="display-4 text-white">
                        Get Landkit and save your time.
                    </h1>

                    
                    <p className="font-size-lg text-muted mb-6 mb-md-8">
                        Stop wasting time trying to do it the "right way" and build a site from scratch. Landkit is
                        faster, easier, and you still have complete control.
                    </p>

                    
                    <a href="https://themes.getbootstrap.com/product/landkit/" target="_blank"
                       className="btn btn-success lift">
                        Start now <i className="fe fe-arrow-right"></i>
                    </a>

                </div>
            </div>
            
        </div>
        
    </section>
    <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
</>;

export default HomeView;