import React from "react";
import {
    AuthController,
    Card,
    Controller,
    LoadController, MyComponent,
    Spinner,
    SpinnerBox,
    TimeLeft, UserContext
} from "./Components";


class BalanceView extends AuthController {
    static contextType = UserContext

    constructor(props) {
        super(props)
        this.dataName = {transactions: 'transaction'}
    }


    preloader = () => (
        {
            "ph-col-2": 'ph-avatar', 'ph-col-10': '',
            'ph-col-12': [
                {
                    'ph-row': ['ph-col-4 big', "ph-col-8 empty big"]
                },
                {
                    'ph-row': ["ph-col-2 big", "ph-col-12", "ph-col-12", "ph-col-12"]
                }
            ]
        }
    );


    renderLoaded() {
        console.log(this.state)

        document.title = `Balance`

        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    <h2>{this.state.user}</h2>

                    <Card>
                        Balance <b>{USDollar.format(this.state.transactions.balance/ 100)}</b>
                    </Card>

                    <table className="table">
                    {this.state.transactions.transactions.map(transaction => <tr>
                        <td>{USDollar.format(transaction.amount / 100)}</td>
                        <td>{new Date(transaction.created_at).toLocaleDateString()}</td>
                        <td>{transaction.transaction_type}</td>
                    </tr>)}
                    </table>

                    <br/>
                </div>
            </div>
        </div>;
    }
}

export {BalanceView};
