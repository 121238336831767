import React from "react";
import {
    Button,
    CollapsibleItemList,
    Controller,
    SpinnerBox,
    LoadController, sleep,
} from "./Components";
import SmartPaymentButtons, { PayPalSDKWrapper } from 'react-smart-payment-buttons';
import {PaypalPayment, StripePayment, TestPayment} from "./Payments";

const SubHeader = props => <span>pay</span>;
const Header = props => <div>{props.item.title}</div>;

const Body = props => {
    switch (props.item.type) {
        case 'paypal':
        case 'paypalTest':
            return <div><PaypalPayment invoice={props.context.state.invoice} params={props.item} context={props.context} /></div>;
        case 'test':
            return <div><TestPayment invoice={props.context.state.invoice}  context={props.context} /></div>;
        case 'stripe':
            return <div><StripePayment invoice={props.context.state.invoice}  context={props.context} /></div>;
        default:
            return <div datatype={props.item.type}/>;
    }
};

// class TestPayment extends Controller {
//     render() {
//         return <Button onClick={async event => await this.props.context.pay('test')}>Pay test!</Button>
//     }
// }
//
// class StripePayment extends Controller {
//     render() {
//         return <Button onClick={async event => await this.props.context.checkout('stripe')}>Pay Stripe!</Button>
//     }
// }

// class PaypalPayment extends Controller {
//     render() {
//         return <PayPalSDKWrapper clientId={this.props.params.client_id}>
//                     <SmartPaymentButtons
//                 createOrder={(data, actions) =>
//                     actions.order.create({
//                         purchase_units: [{
//                             invoice_number: this.props.invoice.id,
//                             amount: {
//                                 value: this.props.invoice.amount/100
//                             },
//                             description: this.props.invoice.description,
//                         }]
//                     })
//                 }
//                 onApprove={async (data, actions) => {
//                     console.log(data);
//                     await this.props.context.pay(this.props.params.type, data);
//                 }}
//             />
//         </PayPalSDKWrapper>
//     }
// }

class PaymentView extends LoadController {
    constructor(props) {
        super(props);
        // this.id = this.props.match.params.id;
        this.id = 1
        this.state = {
            paymentProviders: [],
        }
    }

    preloader = () => ({'ph-col-12': [
        {'ph-row': ['ph-col-2 big', "ph-col-10 empty big"]},
        {'ph-row': ["ph-col-6 big", "ph-col-4 empty", "ph-col-2 big"]},
        {'ph-row': ["ph-col-12"]},
        {'ph-row': ["ph-col-12"]},
        {'ph-row': ["ph-col-12"]}
        ]});

    dataToLoad() {
        return {invoice: this.api.loadInvoice(this.id), paymentProviders: this.api.loadPayProviders(this.id)};
    }

    // async pay(pay_type, data = {}) {
    //     this.setState({loading: true});
    //     await this.api.executePayment(this.id, pay_type, data);
    //     this.redirect('/dashboard');
    // }

    async checkout(pay_type, data = {}) {
        this.setState({loading: true});
        const redirect = await this.api.checkout(this.id, pay_type, data);
        this.redirect(redirect);
    }

    renderLoaded() {
        return <section className="pt-4 pt-md-11">
            <div className="container">
                <h1>Pay invoice</h1>

                { this.state.loading && <SpinnerBox />}

                <table className="table">
                    <tr>
                        <td>{this.state.invoice.description}</td>
                        <td>${(this.state.invoice.amount/100).toFixed(2)}</td>
                    </tr>
                </table>

                <CollapsibleItemList id="payments_list"
                 Header={Header} SubHeader={SubHeader}
                 ItemBody={Body} context={this}
                 items={this.state.paymentProviders} />


                <button className="btn btn-link" onClick={event => this.props.history.goBack()}>Back</button>
            </div>
        </section>
    }
}

export default PaymentView;
