import React from "react"
import {Controller} from "./Components"

class SendInvitation extends Controller {
    submit(e) {
        this.api.sendInvitations(this.state.email, this.state.text, this.state.comp).then(console.log).catch(console.error)
    }

    render() {
        return <div className="container">

            Text:
        <textarea className="form-control" rows="5" cols="15" onChange={this.updateState('text')}/>
            Emails:
        <textarea className="form-control" rows="5" cols="15" onChange={this.updateState('email')}/>
        Compensation:
        <input type="number" onChange={this.updateState('comp')}/>
        <button className="btn btn-dark-soft" onClick={e => this.submit(e)}>Send</button>
        </div>
    }
}

export {SendInvitation}