import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import './App.css';

import {
    Container,
    Controller,
    FacebookButton,
    GoogleButton,
    H1, LinkedinButton,
    MyComponent,
    Txt,
    UserContext
} from "./Components";
import {ViewUserPublic} from "./ViewUser";
import {UserDashboard} from "./UserDashboard";
import Auth from "./Auth";
import Menu from "./Menu";
import HomeView from "./Home";
import {RequestView} from "./RequestView";
import {IncomingView} from "./IncomingView";
import {SentView} from "./SentView";
import {SignUp} from "./SignUp";
import {MessagesView} from "./MessagesView";
import {DialogView} from "./DialogView";
import {ProfileView} from "./ProfileView";
import {SendInvitation} from "./SendInvitation";
import {SearchView} from "./SearchView";
import {Signed} from "./Signed";
import {BalanceView} from "./BalanceView";
import {UserListDashboard} from "./UserListDashboard";
import {CallRequestView} from "./CallRequestView";
import {InvoiceView} from "./InvoiceView";
import {ConnectionView} from "./ConnectionView";
import {ConnectionProfileView} from "./ConnectionProfileView";
import {GoogleOAuthProvider} from "@react-oauth/google";

class Logout extends Controller {
    componentDidMount() {

        this.repo.setToken(null).then(result => {
            console.log(result)
            window.location.href = "/"
        })

        // window.gapi.load('auth2', () => {
        //     const auth2 = window.gapi.auth2.init({
        //         client_id: '569165707808-eit11oc7sa4810pdi6qcd3ve7agemvis.apps.googleusercontent.com',
        //         cookiepolicy: 'single_host_origin',
        //         // Request scopes in addition to 'profile' and 'email'
        //         //scope: 'additional_scope'
        //     });
        //     new Auth().logout(auth2);
        // });
    }

    render() {
        return <div/>
    }
}

class Signin extends Controller {
    componentDidUpdate() {
        if (this.props.user) {
            this.redirect('/my');
        }
    }
    async componentDidMount() {
        try {
            await this.api.getUser();
            this.redirect('/my')
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return <section className="section-border border-primary">
            <div className="container d-flex flex-column">
                <H1>Sign in</H1>
                <Txt>Simplify your workflow in minutes</Txt>
            {/*<form className="mb-6">*/}
            {/*    <InputGroup label="Email address" type={"email"} id="email" placeholder="email" />*/}
            {/*    <InputGroup label="Password" type="password" id="password" />*/}
            {/*    <Button caption="Sign in" type="submit"/>*/}
            {/*</form>*/}
            {/*    <hr/>*/}
                <GoogleButton caption={"Sign in with Google"} id={"google_signin"} onSigned={this.props.onSignin}/>
                <FacebookButton caption={"Sign in with Facebook"} id={"facebook_signin"} onSigned={this.props.onSignin}/>
                <LinkedinButton caption={"Sign in with LinkedIn"} id={"linkedin_signin"} onSigned={this.props.onSignin}/>
        </div>
        </section>
    }
}

const STATES = {
    init: 'Payment Awaiting',
    sent: 'Pending',
    accepted: 'User accepted',
    declined: 'User declined',
    recruiter_declined: 'Declined',
    recruiter_accepted: 'Sent interview invitation',
};

class App extends MyComponent {
    constructor(props) {
        super(props)
        this.state = {user: null, waiting: true}
        this.api = Container.getApi()
        this.repo = Container.getRepo()
        this.logout = this.logout.bind(this)
        this.signing = this.signing.bind(this)
    }

    async componentDidMount() {
        this.setState({user: await this.repo.loadMyself(), waiting: false});
    }

    signing(user) {
        this.setState({user: user});
    }

    logout() {
        window.gapi.load('auth2', () => {
            const auth2 = window.gapi.auth2.init({
                client_id: '569165707808-eit11oc7sa4810pdi6qcd3ve7agemvis.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
                // Request scopes in addition to 'profile' and 'email'
                //scope: 'additional_scope'
            });
            auth2.signOut();
        });

        new Auth().logout();
        this.setState({user: null});
        // window.location = '/';
    }

    render() {
        return <GoogleOAuthProvider clientId="569165707808-eit11oc7sa4810pdi6qcd3ve7agemvis.apps.googleusercontent.com">
            <Menu user={this.state.user} logout={this.logout}/>
            <Router>
                <UserContext.Provider value={this.state.user}>
                    <Route path="/" exact component={HomeView}/>
                    <Route path="/profile" exact component={ProfileView}/>
                    <Route path="/search" exact component={SearchView}/>

                    <Route path="/login" component={Signin} onSignin={this.signing}/>

                    {/*<Route path="/view/:path"*/}
                    {/*       render={(props) => <ViewUser {...props} user={this.state.user} onSignin={this.signing}/>}/>*/}
                    {/*<Route path="/viewPublic/:path" component={ViewUserPublic}  user={this.state.user} onSignin={this.signing} />*/}
                    <Route path="/request/:request" exact
                           render={(props) => <RequestView {...props} onSignin={this.signing}/>}/>
                    <Route path="/request/:request/:token"
                           render={(props) => <RequestView {...props} onSignin={this.signing}/>}/>

                    {/*<Route path="/signin"*/}
                    {/*       render={(props) => <Signin {...props} onSignin={this.signing} user={this.state.user}/>}/>*/}

                    <Route path="/messages/:path/:dialog_created" render={(props) => <MessagesView {...props} user={this.state.user}/>}/>

                    {!this.state.waiting && <Route path="/user/:path" render={(props) => <ViewUserPublic {...props} user={this.state.user} onSignin={this.signing} />}/>}

                    {/*<Route path="/dialog/:path/:created" component={UserDialogView} user={this.state.user} />*/}
                    <Route path="/dialog/:id" render={(props) => <DialogView {...props} user={this.state.user} />} exact/>
                    <Route path="/dialog/:id/:token" render={(props) => <DialogView {...props} user={this.state.user} />}/>

                    <Route path="/my" component={UserListDashboard} exact/>
                    <Route path="/send_invitations" component={SendInvitation} exact/>

                    <Route path="/incoming" exact component={IncomingView}/>
                    <Route path="/incoming/:token" component={IncomingView}/>
                    <Route path="/sent" component={SentView}/>
                    <Route path="/balance" component={BalanceView}/>

                    <Route path="/call/:user_id" exact component={CallRequestView}/>
                    <Route path="/call/:user_id/:token" component={CallRequestView}/>

                    <Route path="/invoice/:id" component={InvoiceView}/>

                    <Route path="/connection/:id" component={ConnectionView}/>

                    <Route path="/connection_param" component={ConnectionProfileView}/>

                    <Route path="/signup" component={SignUp}/>
                    <Route path="/signed" component={Signed}/>
                    <Route path="/logout" render={(props) => <Logout />}/>
                </UserContext.Provider>
            </Router>
        </GoogleOAuthProvider>
    }
}

export default App;
