import React from "react";
import {ApiService, ApiError} from "./ApiService";
import Auth from "./Auth";
import AnimateHeight from 'react-animate-height';
import {createBrowserHistory} from "history";
import config from "./config";
import * as qs from 'query-string';
import LocalRepo from "./services/LocalRepo";
import {GoogleLogin} from '@react-oauth/google';

const sleep = ms => new Promise(resolve => setTimeout(resolve,ms));

const history = createBrowserHistory();

const Slider = props => <AnimateHeight height={props.show ? 'auto' : 0}>{props.children}</AnimateHeight>

class ArrowButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading: false};
        this.click = this.click.bind(this);
    }

    click() {
        this.setState({loading: true});
        this.props.onclick();
    }

    stopLoading() {
        this.setState({loading: false});
    }

    render() {
        return  <button className="btn btn-primary-soft lift" onClick={this.click}>
            {this.props.children} <span className="ml-3"> {this.state.loading ? <Spinner/> : <i className="fe fe-arrow-right"/> }</span>
        </button>;
    }
}

class Container {
    static getApi() {
        return new ApiService(config.apiUrl);
    }

    static getRepo() {
        return new LocalRepo(this.getApi())
    }
}

const H1 = props => <h1 className="display-2">{props.children}</h1>;
const H2 = props => <h2 className="display-3">{props.children}</h2>;
// const H2 = props => <h2 className="mb-0 font-weight-bold">{props.children}</h2>;
const H3 = props => <h3 className="mb-0 font-weight-bold">{props.children}</h3>;
const Txt = props => <p className="mb-6 text-center text-muted">{props.text}</p>;
const P = props => <p className="font-size-lg text-gray-700 mb-5 mb-md-0">{props.children}</p>;

// const Spinner = props => <FontAwesomeIcon icon={faSync} spin={true} />;
const Spinner = props => <i className="fa fa-sync fa-spin"/>;
const SpinnerBox = props => <div className="container center-box"><Spinner/></div>;
const PlaceholderLoader2= props => <div className="ph-item">
    <div className="ph-col-12">
        <div className="ph-col-2">
            <div className="ph-avatar"/>
        </div>
        <div className="ph-row">
            <div className="ph-col-2 big"/>
            <div className="ph-col-10 empty big"/>
        </div>
        <div className="ph-row">
            <div className="ph-col-6 big"/>
            <div className="ph-col-4 empty"/>
            <div className="ph-col-2 big"/>
        </div>
        <div className="ph-row">

            <div className="ph-col-12"/>
            <div className="ph-col-12"/>
            <div className="ph-col-12"/>

        </div>
    </div>
</div>;

class PlaceholderLoader extends React.Component {
    renderElement(element) {
        if (!element) {
            return <div/>;
        }
        if (typeof element === 'string') {
            return <div className={element} key={element}/>
        }

        const self = this;
        return Object.keys(element).map((key, i) => {
            const item = element[key];
            return <div className={key} key={key + i}>
                { Array.isArray(item) ? item.map((sub_item) => self.renderElement(sub_item)) : <div className={item}/> }
            </div>
        });
    }

    render() {
        return <div className="ph-item" key={1}>
            {this.renderElement(this.props.format)}
        </div>;
    }
}

const GreenCheck = props => <i className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-check"/></i>;

const CheckText = props => <div className="d-flex">
    <GreenCheck/>
    <p>{props.children}</p>
</div>;

const Badge = props => <div className="text-center mb-3">
                  <span className="badge badge-pill badge-primary-soft">
                    <span className="h6 text-uppercase">{props.text}</span>
                  </span>
</div>;


const Item = props => <li>{props.done ? <strike>{props.text}</strike> : props.text}</li>;

const List = props => <ul>
    {props.items.map (item => <Item key={item.text} done={item.done} text={item.text}/>)}
</ul>;

const TimeLeft = props => {
    const left = Math.floor((props.time - Date.now())/1000/60);
    const minutes = left % 60;
    const hours = (left - minutes) / 60;
    return left > 0 ? <span>{hours} hours {minutes} minutes left</span> : <span>Expired</span>;
};


class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.enableSwitch = this.enableSwitch.bind(this);
        this.enabled = this.enabled.bind(this);
        this.state = {};
        this.queryParams = this.props?.location ? qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) : {};
    }

    enableSwitch(name) {
        return (event => this.setState({[name]: true}))
    }

    enabled(name) {
        return this.state && this.state[name];
    }

    redirect(url) {
        console.log(url);
        if (this.props.history) {
            return this.props.history.push(url);
        }

        window.location.href = url;
    }

    updateState(name) {
        return (event) => {
            this.setState({[name]: event.target.value});
        }
    }

    getQueryParam(name) {
        return this.queryParams[name];
    }
}


const PriceTag = props => <span><div className="d-flex justify-content-center">
            <span className="h2 mb-0 mt-2">$</span>
            <span className="price display-2 mb-0" data-annual="0" data-monthly="0">{props.price}</span>
            <span className="h2 align-self-end mb-1">/{props.period}</span>
        </div>

        <p className="text-center text-muted mb-5">
        {props.subtext}
    </p>
    </span>;

const Card = props => <div className="card shadow-lg mb-md-0">
    <div className="card-body">
        {props.children}
    </div>
</div>;

class CollapsableList extends MyComponent {
    constructor(props) {
        super(props);
        this.elements = {};
    }

    toggle(item) {
        this.elements[item.id].toggle();
    }

    render() {
        return <div className="card shadow-light-lg accordion mb-5 mb-md-6" id={this.props.id}>
            <div className="list-group">
                {this.props.items.map(item => <CollapsableItem item={item}
                                                          header={this.props.header(item)}
                                                          body={React.createElement(this.props.body, {}, item)}
                                                          header_secondary={this.props.header_secondary && this.props.header_secondary(item)}
                                                          parent={this.props.id}
                                                          id={"item" + item.id}
                                                          key={item.id}
                                                          onClick={this.props.onClick}
                                                          opened={this.props.opened && this.props.opened(item)}
                                                          ref={element => this.elements[item.id] = element}
                />)}
            </div>
        </div>
    }
}

class CollapsableItem extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.state = {opened: this.props.opened};
    }

    open(item) {
        if (this.state.opened) {
            return;
        }
        this.setState({opened: true});
        this.props.onClick && this.props.onClick(this.props.item);
    }

    toggle() {
        this.body.classList.toggle('show');
    }

    componentDidMount() {
        this.myCollapsible.addEventListener('click', this.open);
    }

    componentWillUnmount() {
        this.myCollapsible.removeEventListener('click', this.open)
    }

    render() {
        return <div className={"list-group-item " + (this.state.opened ? 'opened' : 'new')}>
            <a className="d-flex align-items-center text-reset text-decoration-none header" data-toggle="collapse"
               href={"#" + this.props.id}
               role="button" aria-expanded="false" aria-controls={this.props.id}
               ref={node => (this.myCollapsible = node)}
            >
                {this.props.header}
                <div className="text-muted ml-auto">
                    <span className="font-size-sm mr-4 d-none d-md-inline">
                        {this.props.header_secondary}
                    </span>
                    <span className="collapse-chevron text-muted">
                        <i className="fe fe-lg fe-chevron-down"/>
                    </span>
                </div>
            </a>
            <div className="collapse" id={this.props.id} data-parent={"#" + this.props.parent} ref={element => this.body = element}>
                <div className="py-5">
                    {this.props.body}
                </div>
            </div>
        </div>
    }
}

class Controller extends MyComponent {
    constructor(props) {
        super(props);
        /** @var {ApiService} */
        this.api = Container.getApi();
        this.repo = Container.getRepo()
        this.state = {};
    }

    param(name) {
        return this.props.match.params[name]
    }

    handleError(e) {
        console.error(e);
    }
}


const InputGroup = props => <div className="form-group">
    <label htmlFor="email">
        {props.label}
    </label>
    <input type={props.type} className="form-control" id={props.id} placeholder={props.placeholder}/>
</div>;

class Button extends React.Component {
    render() {
        const classNames = this.props.className ? this.props.className : "btn-block btn-primary"
        return <button className={"btn " + classNames} type={this.props.type} id={this.props.id} onClick={this.props.onClick}>{this.props.caption}{this.props.children}</button>
    }
}

class GoogleButton extends React.Component {
    constructor(props) {
        super(props);
        this.auth = this.auth.bind(this);
        this.state = {
            loading: false
        };
    }

    async auth(token) {
        this.setState({loading: true});
        const result = await (new Auth().signin('google', token));
        if (this.props.onSigned) {
            this.props.onSigned(result);
        }
        this.setState({loading: false});
    }

    componentDidMount() {
        const self = this;
        // window.gapi.load('auth2', function () {
        //     // Retrieve the singleton for the GoogleAuth library and set up the client.
        //     const auth2 = window.gapi.auth2.init({
        //         client_id: '569165707808-eit11oc7sa4810pdi6qcd3ve7agemvis.apps.googleusercontent.com',
        //         cookiepolicy: 'single_host_origin',
        //         // Request scopes in addition to 'profile' and 'email'
        //         //scope: 'additional_scope'
        //     });
        //
        //     auth2.attachClickHandler(self.button, {}, googleUser => self.auth(googleUser.getAuthResponse().id_token), console.log);
        // });
    }

    render() {
        return <div>
            {/*<GoogleOAuthProvider clientId="569165707808-eit11oc7sa4810pdi6qcd3ve7agemvis.apps.googleusercontent.com>">*/}
                <GoogleLogin
                    auto_select="true"
                    onSuccess={async credentialResponse => {
                        console.log(credentialResponse)
                        const result = await this.auth(credentialResponse.credential)
                        if (this.props.onSigned) {
                            this.props.onSigned(result);
                        }
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    useOneTap
                />

            {/*</GoogleOAuthProvider>*/}


            {/*<button className="btn btn-block btn-primary" type={this.props.type} id={this.props.id} onClick={this.props.onClick} ref = {c => this.button = c}>*/}
                {/*    {this.state.loading && <Spinner/>} <i className='fab fa-google'/>  {this.props.caption}{this.props.children}*/}
                {/*</button>*/}
        </div>
    }
}

class FacebookButton extends React.Component {
    constructor(props) {
        super(props);
        this.auth = this.auth.bind(this);
        this.checkLoginState = this.checkLoginState.bind(this);
        this.statusChangeCallback = this.statusChangeCallback.bind(this);
        this.state = {loading: false}
    }

    async auth(token) {
        const result = await (new Auth().signin('facebook', token));
        if (this.props.onSigned) {
            this.props.onSigned(result);
        }
    }

    loadFbLoginApi() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '2284561958333342',
                cookie     : true,
                xfbml      : true,
                version    : 'v4.0'
            });
        };

        (function(d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    componentDidMount() {
        this.loadFbLoginApi();
    }

    statusChangeCallback(response) {
        console.log(response);
        if (response.status === 'connected') {
            this.setState({loading: true});
            this.auth(response['authResponse']['accessToken'])
                .then(result => this.setState({loading: false}))
                .catch(console.error);
        } else if (response.status === 'not_authorized') {
            console.log("Logging in...");
            window.FB.login(this.statusChangeCallback, {scope: 'public_profile, email'});
        } else {
            console.log("Please log into this facebook.");
            window.FB.login(this.statusChangeCallback, {scope: 'public_profile, email'});
        }
    }

    checkLoginState() {
        window.FB.getLoginStatus(this.statusChangeCallback);
    }

    logout() {
        window.FB.logout();
    }

    render() {
        return <button className="btn btn-block btn-primary" onClick={this.checkLoginState}><i className='fab fa-facebook'/> Sign in with Facebook</button>;
    }
}
class LinkedinButton extends React.Component {
    startLogin() {
        const client_id = '86x7f72yxwoms6'
        const redirect_uri = 'http://localhost:3000/signed'
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=foobar&scope=r_liteprofile%20r_emailaddress`
        window.location.href = url
    }

    render() {
        return <button className="btn btn-block btn-primary" onClick={this.startLogin}><i className='fab fa-linkedin'/> Sign in with LinkedIn</button>;
    }
}

class LoadController extends Controller {
    constructor(props) {
        super(props);
        this.state = {load_state: 'init'};
    }

    loaded() {
        return this.state.load_state === 'loaded';
    }

    async componentDidMount() {
        if (this.dataName) {
            let dataToLoad = {}
            for (const key in this.dataName) {
                dataToLoad[key] = this.api.loadData(this.dataName[key])
            }
            await this.loadData(dataToLoad)
            return
        }

        await this.loadData(this.dataToLoad());
    }

    dataToLoad() {return {}}
    renderLoaded() {return {}}
    preloader() { return {'ph-row': 'ph-col-2 big'}}

    render() {
        if (this.state.load_state === 'loaded') {
            return this.renderLoaded();
        }

        if (this.state.load_state === 'error') {
            if (this.state.load_error instanceof ApiError) {
                if (this.state.load_error.status === 401) {
                    // window.location.href = "/signin"
                    return <SignForm/>
                }
            }
            return <section className="pt-4 pt-md-11">
                <div className="container"><h1>Error :(</h1> <p>{this.state.load_error.message}</p></div>
                <div className="container small">
                    Error code: {this.state.load_error.status}
                </div>
            </section>
        }

        return <div className="container center-box"><PlaceholderLoader format={this.preloader()}/></div>;
    }

    async loadData(load) {
        try {
            const objectKeys = Object.keys(load);
            let promisedProperties = objectKeys.map(key => load[key]);

            const resolvedValues = await Promise.all(promisedProperties);
            const res = resolvedValues.reduce((resolvedObject, property, index) => {
                resolvedObject[objectKeys[index]] = property;
                return resolvedObject;
            }, load);

            console.log("res")
            console.log(res)

            this.setState({load_state: 'loaded', ...res});
        } catch (e) {

            console.info({error: e})
            this.setState({load_state: 'error', load_error: e});
        }
    }
}

class AuthController extends LoadController {
    render() {
        if (this.repo.token) {
            return super.render()
        }

        return <SignForm/>
    }
}

const CollapsibleItemList = props => <div className="card shadow-light-lg accordion mb-5 mb-md-6" id={props.id}>
    <div className="list-group">
        {props.items.map(item => <div className="list-group-item" key={item.id}>
            <a className="d-flex align-items-center text-reset text-decoration-none" data-toggle="collapse"
               href={"#invite_row" + item.id}
               role="button" aria-expanded="false" aria-controls={"invite_row" + item.id}>
                <span className="mr-4">
                    <props.Header item={item} context={props.context} />
                </span>

                <div className="text-muted ml-auto">
                    <span className="font-size-sm mr-4 d-none d-md-inline">
                        <props.SubHeader item={item} context={props.context} />
                    </span>

                    <span className="collapse-chevron text-muted">
                        <i className="fe fe-lg fe-chevron-down"/>
                    </span>
                </div>
            </a>
            <div className="collapse" id={"invite_row" + item.id} data-parent={"#" + props.id}>
                <div>
                    <props.ItemBody item={item} context={props.context} />
                </div>
            </div>
        </div>)}
    </div>
</div>;


class Invite  {
    static INIT = 'init';
    static SENT = 'sent';
    static USER_ACCEPTED = 'accepted';
    static USER_DECLINED = 'declined';
    static RECRUITER_ACCEPTED = 'recruiter_accepted';
    static RECRUITER_DECLINED = 'recruiter_declined';
    static TIMEOUT = 'timeout';
}

class SignForm extends Controller {
    render() {
        return <div>
            <GoogleButton caption={"Sign in with Google"} id={"google_signin"} onSigned={this.props.onSigned}/>
            <FacebookButton caption={"Sign in with Facebook"} id={"facebook_signin"} onSigned={this.props.onSigned}/>
            <LinkedinButton caption={"Sign in with LinkedIn"} id={"linkedin_signin"} onSigned={this.props.onSigned}/>
        </div>
    }
}

const UserContext = React.createContext(null)

export {
    ArrowButton,
    Controller,
    Container,
    H1,
    H2,
    H3,
    Txt,
    P,
    Badge,
    CheckText,
    List,
    TimeLeft,
    MyComponent,
    PriceTag,
    Card,
    CollapsableList,
    CollapsableItem,
    Button,
    GoogleButton,
    InputGroup,
    FacebookButton,
    LinkedinButton,
    AnimateHeight,
    Slider,
    CollapsibleItemList,
    Invite,
    LoadController,
    Spinner,
    SpinnerBox,
    GreenCheck,
    PlaceholderLoader,
    sleep,
    UserContext,
    SignForm,
    AuthController,
}
