import React from "react";
import {Link} from "react-router-dom";
import {
    AuthController,
    Card,
    CollapsableList, Container,
    Controller,
    H1,
    H2,
    List,
    LoadController,
    MyComponent,
    Spinner,
    TimeLeft
} from "./Components";

const MyInfo = props => <div>{props.user.name} <Link to={"/user/" + props.user.idd}>View my profile</Link></div>;

const FinishSetup = props => <div>
    <H2 text="Please finish setup"/>
    <List items={[{
        text: 'Connect Facebook',
        done: true
    }, {text: 'Connect Linkedin'}, {text: 'Invite friends'}, {text: 'Add avatar'}]}/>
</div>;

class Dialog extends MyComponent {
    constructor(props) {
        super(props)

        this.state = {
            users: []
        }
        this.repo = Container.getRepo()
    }

    async componentDidMount() {
        const users = []
        for (const id of this.props.dialog.users) {
            if (this.props.user.id === id) {
                continue
            }
            const user = await this.repo.get('user', id)
            if (user) {
                users.push(user)
            }
        }
        this.setState({users: users})
    }

    render() {
        const dialog = this.props.dialog

        if (this.state.users.length === 0) {
            return <div></div>
        }

        if (dialog.status === 'archived' || dialog.status === 'timeout') {
            return <div></div>
        }

        return <Card>
            Status: {dialog.status}<br/>
            {this.state.users.map(user => <div><a href={`/user/${user.path}`}>Name: {user.name}</a></div>)}
            <br/>
            <a className="btn btn-primary-soft" href={`/dialog/${this.props.dialog.id}`}>view</a>
            &nbsp;
            <button className="btn btn-primary-soft" onClick={this.props.onArchive}>archive</button>
        </Card>
    }
}

class UserDashboard extends AuthController {
    constructor(props) {
        super(props);
        this.dataName = {dashboard: 'dashboard'}
    }

    archive(dialog) {
        this.api.archiveDialog(dialog.id).then(console.log)
    }

    renderLoaded() {
        const user = this.state.dashboard.user;
        return <section className="pt-4 pt-md-11">
            <div className="container">
                <H1>Dashboard</H1>
                <MyInfo user={user}/>
                <a href="/send_invitations">Send invitations</a>
                {/*<FinishSetup user={user}/>*/}
                <H2>Sent</H2>
                {this.state.dashboard.sent.map(dialog => <Dialog dialog={dialog} user={user} onArchive={_ => this.archive(dialog)}/>)}
                <H2>Incoming</H2>
                {this.state.dashboard.incoming.map(dialog => <Dialog dialog={dialog} user={user} onArchive={_ => this.archive(dialog)} />)}
            </div>
        </section>
    }
}

export {UserDashboard};
