import React from "react";
import {
    Card,
    GreenCheck,
    LoadController,
    MyComponent, H3, SignForm, UserContext
} from "./Components";
import {ConnectionParamView, Connections} from "./ConnectionsView";


// class ViewUser extends Controller {
//     constructor(props) {
//         super(props);
//         this.path = this.props.match.params.path;
//         this.signed = this.signed.bind(this);
//     }
//
//     componentDidUpdate(prevProps) {
//         if (prevProps.user !== this.props.user) {
//             this.setState({self: this.props.user});
//         }
//     }
//
//     async componentDidMount() {
//         if (!localStorage.getItem('token')) {
//             await this.loadPublic();
//             return;
//         }
//
//         try {
//             const data = await Promise.all([this.api.loadUser(this.path), this.api.getUser()])
//             this.setState({user: data[0], self: data[1]})
//         } catch (e) {
//             console.error(e)
//             await this.loadPublic()
//         }
//     }
//
//     async loadPublic() {
//         try {
//             this.setState({user: await this.api.loadUserPublic(this.path)});
//         } catch (e) {
//             this.setState({error: e})
//         }
//     }
//
//     async signed(user) {
//         const data = await Promise.all([this.api.loadUser(this.path), this.api.getUser()]);
//         this.setState({user: data[0], self: data[1]});
//         this.props.onSignin(data[1]);
//     }
//
//     preloader = () => (
//         {
//             "ph-col-2": 'ph-avatar', 'ph-col-10': '',
//             'ph-col-12': [
//                 {
//                     'ph-row': ['ph-col-4 big', "ph-col-8 empty big"]
//                 },
//                 {
//                     'ph-row': ["ph-col-2 big", "ph-col-12", "ph-col-12", "ph-col-12"]
//                 }
//             ]
//         }
//     );
//
//     render() {
//         if (this.state.error) {
//             return <div className="container center-box">Error: {this.state.error.message}</div>;
//         }
//
//         if (!this.state.user) {
//             return <div className="container center-box"><PlaceholderLoader format={this.preloader()}/></div>
//         }
//
//         if (this.state.self) {
//             return <AdvancedView history={this.props.history} path={this.path} user={this.state.user} self={this.state.self}/>
//         }
//
//         return <SimpleView user={this.state.user} onSigned={this.signed} />;
//     }
// }

class ViewUserPublic extends LoadController {
    constructor(props) {
        super(props)
        this.path = this.props.match.params.path
        this.onSigned = this.onSigned.bind(this)
    }

    dataToLoad() {
        return {info: this.api.loadUserPublic(this.path)}
    }

    preloader = () => [['ph-col-2 big', "ph-col-10 empty big"], ["ph-col-6 big", "ph-col-4 empty", "ph-col-2 big"], ["ph-col-12"], ["ph-col-12"], ["ph-col-12"]];

    onSigned(user) {
        console.log(user)
        this.setState({user: user})
        this.props.onSignin(user)
    }

    renderLoaded() {
        const self = this.props.user || this.state.user
        const profile = this.state.info;
        return <section className="pt-4 pt-md-11">
            <div className="container">
                {self ? <AdvancedView path={this.path} user={profile} self={self}/> : <SimpleView user={profile} onSigned={this.onSigned} />}
            </div>
        </section>
    }
}


const SimpleView = props => <div className="section">
    <div className="container">
        <div className="col-md-6 col-sm-12">
            <H3><GreenCheck/>{props.user.show_name}</H3>
            <hr/>
            <h3>Sign in to send a messages</h3>
            <SignForm onSigned={props.onSigned}/>
            <br/>
        </div>
    </div>
</div>;

class Dialog extends MyComponent {
    constructor(props) {
        super(props)
    }

    render() {
        return <Card>
            <a href={`/dialog/${this.props.dialog.id}`}>
                {new Date(this.props.dialog.created_at).toLocaleDateString()}
            </a><br/>
            {this.props.dialog.status}<br/>
            {this.props.dialog.text}
        </Card>
    }
}


class AdvancedView extends LoadController {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            invite: false,
            show: true,
            validating: false,
        };
        this.sendInvitation = this.sendInvitation.bind(this);
    }

    dataToLoad() {
        return {
            dialogs: this.api.getDialogs(this.props.path),
            connectionDashboard: this.api.connectionDashboard(this.props.path),
        }
    }

    async sendInvitation(message) {
        this.setState({validating: true});

        let payload = this.state
        payload['message'] = message
        const result = await this.api.sendInitMessage(this.props.path, payload);
        this.redirect(`/dialog/${result.id}`);
    }

    renderLoaded() {
        const user = this.context
        const self = user.id === this.props.user.id

        return <>
            <div className="section">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-md-6 col-sm-12">
                            <H3><GreenCheck/>{this.props.user.show_name}</H3>
                        </div>
                    </div>
                </div>
            </div>

            {self && <a href="/profile">Edit profile</a>}

            <section className="bg-gradient-light">
                { Object.keys(this.state.connectionDashboard.connectionsParams).map(connectionType => <ConnectionParamView
                    user={this.props.user}
                    connectionType={connectionType}
                    connectionParam={this.state.connectionDashboard.connectionsParams[connectionType]}
                    connectionRequests={this.state.connectionDashboard.connectionsRequests.filter(request => request.type === connectionType)}
                    />) }
            </section>

            <section className="bg-gradient-light">
                {!self && <div className="container">
                    {this.state.dialogs.length > 0 && <h3>You have active dialogs:</h3>}
                    {this.state.dialogs.map(dialog => <Dialog dialog={dialog}/>)}
                </div>}
                {self && <div className="container">
                    You can not send messages to yourself.
                </div>}
            </section>
        </>
    };
}

export {ViewUserPublic, SignForm};
