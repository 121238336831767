const dev = {
    apiUrl: 'http://localhost:4000/'
};

const prod = {
    apiUrl: 'https://api.flashreply.com/'
};

const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;

export default {
    stage: process.env.REACT_APP_STAGE,
    ga: 'UA-153586097-1',
    ...config
};

