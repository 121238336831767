import React from "react";
import {Link} from "react-router-dom";
import {
    AuthController,
    Card,
    CollapsableList, Container,
    Controller,
    H1,
    H2,
    List,
    LoadController,
    MyComponent,
    Spinner,
    TimeLeft
} from "./Components";
import LocalRepo from "./services/LocalRepo";

const MyInfo = props => <div>{props.user.name} <Link to={"/user/" + props.user.idd}>View my profile</Link></div>;

class UserView extends MyComponent {
    constructor(props) {
        super(props)

        this.state = {
            users: []
        }
        this.repo = Container.getRepo()
    }

    render() {
        const user = this.props.user

        return <Card>
            {user.name}
            <a className="btn btn-primary-soft" href={`/user/${this.props.user.id}`}>view</a>
            &nbsp;
            <button className="btn btn-primary-soft" onClick={this.props.onArchive}>archive</button>
        </Card>
    }
}

class UserListDashboard extends AuthController {
    constructor(props) {
        super(props);
        this.dataName = {dashboard: 'user_dashboard'}
    }

    archive(dialog) {
        this.api.archiveDialog(dialog.id).then(console.log)
    }

    renderLoaded() {
        const user = this.state.dashboard.user;
        return <section className="pt-4 pt-md-11">
            <div className="container">
                <H1>Dashboard</H1>
                <MyInfo user={user}/>
                <a href="/send_invitations">Send invitations</a>
                {this.state.dashboard.users.map(user => <UserView user={user}/>)}
            </div>
        </section>
    }
}

export {UserListDashboard};
