import React from "react";
import {
    Controller, MyComponent,
} from "./Components";
import {Message} from "./Message";

class DialogMessages extends MyComponent {
    render() {
        return <div className="section">
            <div className="container">
                <div className="col-md-12 col-sm-12">
                    {this.props.messages.map(message => <Message message={message}/>)}
                </div>
            </div>
        </div>
    }
}

export {DialogMessages}
