import React from "react";
import {Controller,} from "./Components";

class SignUp extends Controller {
    constructor(props) {
        super(props);
        this.signUp = this.signUp.bind(this);
    }

    async signUp() {
        await this.api.joinWaitList();
        this.setState({signed: true})
    }

    render() {
        return <section className="pt-4 pt-md-11">
            <div className="container center">
                <h2>
                    Want to sign up?
                </h2>

                { !this.state.signed && <button className="btn btn-success-soft" onClick={this.signUp}>Join the wait list</button> }
                { this.state.signed && <p>Thank you for joining the waiting list. We will send you an invite soon.</p> }
            </div>
        </section>
    }
}

export {SignUp};
